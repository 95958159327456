import * as React from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table'
import i18n, { keys } from '../../../i18n'
import { useRedux } from '../../../hooks/useRedux'
import { useDefaultTableConfig } from '../../../UI/MaterialReactTable/useDefaultTableConfig'
import { Translation } from '../../../graphql/types'
import { selectPreferredLanguage } from '../../../data/selectPreferredLanguage'
import { selectOrganization } from '../selectOrganization'

export interface RefreshableRef {
  refresh: () => void
}

interface SubscriptionRow {
  name: string
  subscription: string
  activatedAt: string
  expiresAt: string
}

export const OrganizationSubscription: React.FC = () => {
  const [state] = useRedux()
  const organization = selectOrganization(state)
  const preferredLanguage = selectPreferredLanguage(state)

  const [columnFilters, setColumnFilters] =
    React.useState<MRT_ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [sorting, setSorting] = React.useState<MRT_SortingState>([])

  const [pagination, setPagination] = React.useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })

  const getSubscriptionName = React.useMemo(
    () =>
      (subscriptionName: Translation | undefined): string => {
        if (!subscriptionName) return ''

        if (typeof subscriptionName === 'string') return subscriptionName
        return subscriptionName[preferredLanguage] || ''
      },
    [preferredLanguage]
  )

  const organizationName = React.useMemo(() => {
    return organization?.name
  }, [organization])

  const subscriptions = React.useMemo(() => {
    return state.subscription.subscriptions
  }, [state.subscription.subscriptions])

  const rowCount = React.useMemo(() => {
    return state.subscription.subscriptions.length
  }, [state.subscription.subscriptions.length])

  const subscriptionFilterOptions = React.useMemo(() => {
    if (!subscriptions) return []
    const uniqueSubscriptions = new Set(
      subscriptions.map((sub) =>
        getSubscriptionName(sub.SubscriptionType?.name)
      )
    )
    return Array.from(uniqueSubscriptions).map((name) => ({
      value: name,
      label: name,
    }))
  }, [subscriptions, getSubscriptionName])

  const subscriptionRows = React.useMemo(() => {
    if (!subscriptions) return []

    let filteredData = [...subscriptions]

    columnFilters.forEach((filter) => {
      const { id, value } = filter
      if (id === 'subscription' && Array.isArray(value)) {
        filteredData = filteredData.filter((row) =>
          value.includes(getSubscriptionName(row.SubscriptionType?.name))
        )
      }
    })

    if (globalFilter) {
      filteredData = filteredData.filter((row) =>
        ['name', 'SubscriptionType.name', 'createdAt', 'expiresAt'].some(
          (key) => {
            let value
            if (key === 'SubscriptionType.name') {
              value = getSubscriptionName(row.SubscriptionType?.name)
            } else if (key === 'name') {
              value = organizationName
            } else {
              value = row[key as keyof typeof row]
            }
            return String(value)
              .toLowerCase()
              .includes(globalFilter.toLowerCase())
          }
        )
      )
    }

    if (sorting.length) {
      const { id, desc } = sorting[0]
      filteredData.sort((a, b) => {
        const aValue = a[id as keyof typeof a]
        const bValue = b[id as keyof typeof b]
        if (aValue === undefined || bValue === undefined) return 0
        if (aValue < bValue) return desc ? 1 : -1
        if (aValue > bValue) return desc ? -1 : 1
        return 0
      })
    }

    return filteredData.map((subscription) => ({
      name: organizationName || '',
      subscription: getSubscriptionName(subscription.SubscriptionType?.name),
      activatedAt: i18n.toDateShort(subscription.createdAt),
      expiresAt: i18n.toDateShort(subscription.expiresAt),
    }))
  }, [
    subscriptions,
    organizationName,
    getSubscriptionName,
    columnFilters,
    globalFilter,
    sorting,
  ])

  const columns = React.useMemo<MRT_ColumnDef<SubscriptionRow>[]>(
    () =>
      [
        {
          accessorKey: 'name',
          header: i18n.t(keys.nameHeader),
          size: 100,
          grow: false,
        },
        {
          accessorKey: 'subscription',
          header: i18n.t(keys.subscriptionHeader),
          filterVariant: 'multi-select',
          filterSelectOptions: subscriptionFilterOptions,
          Cell: ({ cell }) => {
            const value = cell.getValue<string>()
            let bgColor = '#262626'
            if (value.toLowerCase().includes('fieldsense'))
              bgColor = '#5CC46C33'
            if (value.toLowerCase().includes('pinpoint')) bgColor = '#2e5cb8'

            return (
              <Typography
                sx={{
                  backgroundColor: bgColor,
                  color: 'white',
                  borderRadius: '12px',
                  padding: '4px',
                  display: 'inline-block',
                  width: '120px',
                  textAlign: 'center',
                }}
              >
                {value}
              </Typography>
            )
          },
          size: 100,
          grow: false,
          muiTableHeadCellProps: { sx: { width: '10px' } },
        },
        {
          accessorKey: 'activatedAt',
          header: i18n.t(keys.activatedHeader),
          size: 100,
          grow: false,
        },
        {
          accessorKey: 'expiresAt',
          header: i18n.t(keys.expiringHeader),
          size: 200,
          grow: false,
        },
        {
          id: 'contactUs',
          header: '',
          Cell: () => (
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  window.open(
                    'https://vineview.com/connect/',
                    '_blank',
                    'noopener,noreferrer'
                  )
                }}
              >
                {i18n.t(keys.contactSales)}
              </Button>
            </Box>
          ),
          minSize: 200,
          maxSize: 200,
          grow: false,
        },
      ] as MRT_ColumnDef<SubscriptionRow>[],
    [subscriptionFilterOptions]
  )

  const emptySubscriptionView = () => {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={6} md={4} sx={{ paddingLeft: '0 !important' }}>
          <Typography align="center">{i18n.t(keys.noSubscriptions)}</Typography>
        </Grid>
      </Grid>
    )
  }

  const table = useDefaultTableConfig<SubscriptionRow>({
    status: 'resolved',
    columns,
    data: subscriptionRows,
    requiredTableConfig: {
      renderEmptyRowsFallback: emptySubscriptionView,
      rowCount: rowCount,
      state: {
        columnFilters,
        globalFilter,
        pagination: pagination,
        sorting,
      },
      onColumnFiltersChange: setColumnFilters,
      onGlobalFilterChange: setGlobalFilter,
      onPaginationChange: setPagination,
      onSortingChange: setSorting,
    },
  })

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <MaterialReactTable table={table} />
      </Grid>
    </Grid>
  )
}
