import * as React from 'react'
import { PureComponent } from 'react'

import { Button, Icon, Tooltip } from '@mui/material'
import i18n, { keys } from '../i18n'
import { connect } from '../redux/connect'
import { selectNotes, selectProjectsById } from './selectors'
import { AppDispatchProps, RootStore } from '../redux/types'
import * as notes from './redux'
import useSubscriptionFeature from '../subscriptions/hooks/useSubscriptionFeature'

class CreateNoteButton extends PureComponent<
  ReduxProps & AppDispatchProps & { limit: any }
> {
  render() {
    const { totalNotes, limit } = this.props
    const hasReachedNoteLimit = totalNotes >= limit

    return (
      <Button
        onClick={() => this.handleClick(hasReachedNoteLimit)}
        disabled={
          this.props.isCreatingNote ||
          this.props.isMeasureToolActive ||
          this.props.isStatsZonesToolActive
        }
      >
        <Tooltip title={i18n.t(keys.notes.notesPopup.newNote)} placement="left">
          <Icon>note_add</Icon>
        </Tooltip>
      </Button>
    )
  }

  handleClick(hasReachedNoteLimit: boolean) {
    if (hasReachedNoteLimit) {
      this.props.dispatch(notes.actions.toggleNotesLimitDialog(true))
    } else {
      const projectIds = Object.keys(this.props.projectsById).map((id) => id)
      this.props.dispatch(notes.actions.setIsCreatingNote(true))
      this.props.dispatch(notes.actions.setSelectedProjectIds(projectIds))
    }
  }
}

const mapState = (state: RootStore) => ({
  isCreatingNote: state.notes.isCreatingNote,
  projectsById: selectProjectsById(state),
  isMeasureToolActive: state.postgis.isMeasureToolActive,
  isStatsZonesToolActive: state.postgis.isStatsZonesToolActive,
  totalNotes: selectNotes(state).length,
})
type ReduxProps = ReturnType<typeof mapState>

const CreateNoteButtonWrapper = (props: ReduxProps & AppDispatchProps) => {
  const { limit } = useSubscriptionFeature({
    featureType: 'notes',
  })

  return <CreateNoteButton {...props} limit={limit} />
}

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  CreateNoteButtonWrapper
)
