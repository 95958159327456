import { Checkbox, ListItem, ListItemText, Typography } from '@mui/material'
import { createStyles, withStyles, WithStyles } from '@mui/styles'
import * as React from 'react'
import { PureComponent } from 'react'
import { selectIsAdmin } from '../../data/selectMe'
import {
  ParcelData,
  selectGroupDatesByGroupId,
} from '../../data/selectOrgMapData'
import { selectSelectedParcelIds } from '../../data/selectSelectedParcelIds'
import * as userSelection from '../../data/userSelectionRedux'
import i18n, { keys } from '../../i18n'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import { dateOlderThanLimit } from '../../subscriptions/util/limit'
import { toggleArrayValue } from '../../util/toggleArrayValue'
import * as actions from '../actions'
import { dateRangeString } from './getParcelDateString'

interface Props {
  parcel: ParcelData
  flightDateLimitInYears?: number
}

class ParcelListItem extends PureComponent<
  ReduxProps & Props & AppDispatchProps & WithStyles<typeof styles>
> {
  render() {
    const {
      // isAdmin,
      parcel,
      selectedParcelIds,
    } = this.props

    const dates = dateRangeString([
      parcel.flightDates[parcel.flightDates.length - 1],
    ])
    const disabled = !dates

    const datesString = dates || i18n.t(keys.generic.unavailable)

    return (
      <ListItem disabled={disabled} button onClick={this.handleListItemClick}>
        <Checkbox
          checked={selectedParcelIds.has(parcel.id)}
          disableRipple
          color="primary"
          className={this.props.classes.checkbox}
          style={{ marginLeft: 24 }}
        />

        <ListItemText
          style={{ marginLeft: 20 }}
          primary={
            <Typography variant="body2" style={{ textTransform: 'uppercase' }}>
              {parcel.name}
            </Typography>
          }
          disableTypography
          secondary={
            <Typography
              variant="caption"
              style={{ textTransform: 'uppercase' }}
            >
              {datesString}
            </Typography>
          }
        />

        {/* {isAdmin && hasInactiveLayers && (
          <ListItemIcon>
            <Tooltip
              popupAnchor="middle-left"
              originAnchor="middle-right"
              title="Has inactive layers"
              style={{
                margin: 4,
                position: 'absolute',
                top: 0,
                right: 0,
                fontSize: 14,
              }}
            >
              <Icon fontSize="inherit">visibility_off</Icon>
            </Tooltip>
          </ListItemIcon>
        )} */}
      </ListItem>
    )
  }

  handleListItemClick = (event: any) => {
    const {
      dispatch,
      parcel: { id: parcelId, group },
      selectedParcelIds,
      selectedFlightDate,
      flightDatesByGroupId,
      flightDateLimitInYears = Infinity,
    } = this.props

    const flightDates = flightDatesByGroupId[group.id] || []
    const availableFlightDates = flightDates
      .map((fd) => fd.flightDate)
      .filter((date) => !dateOlderThanLimit(date, flightDateLimitInYears))

    const newFlightDate =
      selectedFlightDate && group ? selectedFlightDate : availableFlightDates[0]

    if (typeof event.target.checked === 'boolean') {
      // when clicking the checkbox toggle this parcel's visibility

      const newSelection = toggleArrayValue(
        Array.from(selectedParcelIds),
        parcelId
      )
      dispatch(
        userSelection.update({
          selectedGroupId: newSelection.length > 0 ? group.id : undefined,
          selectedParcelIds: newSelection,

          selectedFlightDate: newFlightDate,
        })
      )
    } else {
      // clicking anywhere else on the row sets only this parcel as visible
      dispatch(
        userSelection.update({
          selectedGroupId: group.id,
          selectedParcelIds: [parcelId],

          selectedFlightDate: newFlightDate,
        })
      )
    }

    this.props.dispatch(actions.setFitSelectedParcels(true))
  }
}

const styles = () =>
  createStyles({
    checkbox: {
      height: 'auto',
      padding: 0,
    },
  })

const mapState = (state: RootStore) => ({
  isAdmin: selectIsAdmin(state),
  selectedParcelIds: selectSelectedParcelIds(state),
  selectedFlightDate: userSelection.getSelectedFlightDate(state),
  flightDatesByGroupId: selectGroupDatesByGroupId(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, Props, AppDispatchProps>(mapState)(
  withStyles(styles)(ParcelListItem)
)
