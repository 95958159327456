import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
} from 'material-react-table'
import { client, gql } from '../../../graphql/client'
import { Subscription, SubscriptionType } from '../../../graphql/types'

export const getSubscriptionTypes = async () => {
  const { subscriptionTypes } = await client.request<{
    subscriptionTypes: SubscriptionType[]
  }>({
    query: gql`
      query GET_SUBSCRIPTION_TYPES {
        subscriptionTypes: SubscriptionType {
          id
          name
        }
      }
    `,
  })
  return subscriptionTypes
}

export const getSubscriptions = async (
  globalFilter: string,
  columnFilters: MRT_ColumnFiltersState,
  pagination: MRT_PaginationState,
  getFullHistory: boolean
) => {
  const where = {
    _and: [
      {
        deletedAt: { _is_null: true },
      },
    ] as any[],
  }

  const tableFilters = {
    _or: [] as any[],
  }

  if (globalFilter) {
    tableFilters._or.push({
      _or: [
        {
          SubscriptionType: {
            id: {
              _ilike: `%${globalFilter}%`,
            },
          },
        },
        {
          Organization: {
            name: {
              _ilike: `%${globalFilter}%`,
            },
          },
        },
        {
          Organization: {
            billingCountry: {
              _ilike: `%${globalFilter}%`,
            },
          },
        },
      ],
    })
  }

  if (columnFilters.length) {
    columnFilters.forEach((filter) => {
      if (filter.value) {
        switch (filter.id) {
          case 'billingCountry':
            tableFilters._or.push({
              Organization: {
                billingCountry: {
                  _ilike: `%${filter.value}%`,
                },
              },
            })
            break
          case 'subscriptionTypeName':
            const selectedSubscriptionTypeIds = filter.value as string[]
            tableFilters._or.push({
              SubscriptionType: {
                _and: [
                  {
                    id: {
                      _in: selectedSubscriptionTypeIds,
                    },
                  },
                ],
              },
            })
            break
          case 'effectiveAt':
            const [effectiveMin, effectiveMax] = filter.value as [
              string,
              string
            ]
            if (effectiveMin && effectiveMax) {
              tableFilters._or.push({
                [filter.id]: {
                  _gte: new Date(effectiveMin),
                  _lte: new Date(effectiveMax),
                },
              })
            }
            if (effectiveMax && !effectiveMin) {
              tableFilters._or.push({
                [filter.id]: {
                  _lte: new Date(effectiveMax),
                },
              })
            }
            if (effectiveMin && !effectiveMax) {
              tableFilters._or.push({
                [filter.id]: {
                  _gte: new Date(effectiveMin),
                },
              })
            }
            break
          case 'expiresAt':
            const [expiresMin, expiresMax] = filter.value as [string, string]
            if (expiresMin && expiresMax) {
              tableFilters._or.push({
                [filter.id]: {
                  _gte: new Date(expiresMin),
                  _lte: new Date(expiresMax),
                },
              })
            }
            if (expiresMax && !expiresMin) {
              tableFilters._or.push({
                [filter.id]: {
                  _lte: new Date(expiresMax),
                },
              })
            }
            if (expiresMin && !expiresMax) {
              tableFilters._or.push({
                [filter.id]: {
                  _gte: new Date(expiresMin),
                },
              })
            }
            break
          default:
            tableFilters._or.push({
              [filter.id]: {
                _ilike: `%${filter.value}%`,
              },
            })
            break
        }
      }
    })
  }

  if (tableFilters._or.length) {
    where._and.push(tableFilters)
  }

  const {
    subscriptions,
    subscriptionAggregate: {
      aggregate: { count },
    },
  } = await client.request<{
    subscriptions: Subscription[]
    subscriptionAggregate: { aggregate: { count: number } }
  }>({
    query: gql`
      query GET_SUBSCRIPTIONS(
        $where: Subscription_bool_exp
        $limit: Int
        $offset: Int
      ) {
        subscriptions: Subscription(
          where: $where
          order_by: { Organization: { name: asc } }
          offset: $offset
          limit: $limit
        ) {
          id
          Organization {
            id
            name
            billingCountry
          }
          SubscriptionType {
            id
            name
          }
          effectiveAt
          expiresAt
        }
        subscriptionAggregate: Subscription_aggregate(where: $where) {
          aggregate {
            count
          }
        }
      }
    `,
    variables: {
      where,
      limit: pagination.pageSize,
      offset: pagination.pageIndex * pagination.pageSize,
    },
  })

  if (!getFullHistory) {
    // filter duplicate subscriptions of the same type, showing only the most recent
    return {
      subscriptions: subscriptions.reduce((acc, sub) => {
        const existing = acc.find(
          (s) =>
            s.Organization.id === sub.Organization.id &&
            s.SubscriptionType?.id === sub.SubscriptionType?.id
        )
        if (!existing) {
          return [...acc, sub]
        }
        if (sub.effectiveAt > existing.effectiveAt) {
          return acc.map((s) =>
            s.id === existing.id ? { ...sub, id: existing.id } : s
          )
        }
        return acc
      }, [] as Subscription[]),
      count,
    }
  }
  return { subscriptions, count }
}

export const createSubscription = async (
  organizationId: string,
  subscriptionTypeId: string,
  effectiveAt: Date,
  expiresAt: Date | null
) => {
  await client.request({
    query: gql`
      mutation CREATE_SUBSCRIPTION(
        $organizationId: Int!
        $subscriptionTypeId: String!
        $effectiveAt: timestamptz!
        $expiresAt: timestamptz
      ) {
        insert_Subscription_one(
          object: {
            organizationId: $organizationId
            subscriptionTypeId: $subscriptionTypeId
            effectiveAt: $effectiveAt
            expiresAt: $expiresAt
          }
        ) {
          id
        }
      }
    `,
    variables: {
      organizationId,
      subscriptionTypeId,
      effectiveAt,
      expiresAt,
    },
  })
}

export const deleteSubscription = async (
  subscriptionId: string | undefined
) => {
  if (!subscriptionId) {
    throw Error('No subscriptionId provided when deleting subscription')
  }
  await client.request({
    query: gql`
      mutation DELETE_SUBSCRIPTION($subscriptionId: uuid!) {
        update_Subscription_by_pk(
          pk_columns: { id: $subscriptionId }
          _set: { deletedAt: "now()" }
        ) {
          id
        }
      }
    `,
    variables: {
      subscriptionId,
    },
  })
}

export const getOrganizations = async () => {
  const { organizations } = await client.request<{
    organizations: { value: string; label: string }[]
  }>({
    query: gql`
      query GET_ORGANIZATIONS {
        organizations: Organization {
          value: id
          label: name
        }
      }
    `,
  })
  return organizations
}
