/**
 * Split an array into two arrays based on a filter function.
 * If the filter function returns true, the item is added to the first array.
 * If the filter function returns false, the item is added to the second array.
 * @param items The array to split
 * @param filter The filter function
 * @returns A tuple of two arrays, the first array contains items that passed the filter, the second array contains items that did not pass the filter
 */
export const splitFilter = <T>(items: T[], filter: (item: T) => boolean) => {
  return items.reduce(
    (acc, item) => {
      const [truthy, falsey] = acc
      return filter(item)
        ? [[...truthy, item], falsey]
        : [truthy, [...falsey, item]]
    },
    [[], []] as [T[], T[]]
  )
}
