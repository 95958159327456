import { Stack } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { ConfirmationModal } from '../../../app/ConfirmationModal/ConfirmationModal'
import useAsync from '../../../hooks/useAsync'
import { getOrganizations } from './queries'
import { SubscriptionDateSelect } from './SubscriptionDateSelect'
import { SubscriptionOrganizationSelect } from './SubscriptionOrganizationSelect'
import { SubscriptionSelect } from './SubscriptionSelect'

interface Props {
  open: boolean
  onConfirm: (
    organizationId: string,
    subscriptionTypeId: string,
    effectiveAt: Date,
    expiresAt: Date | null
  ) => void
  onCancel: () => void
  defaultSelectedOrganization?: {
    value: string
    label: string
  }
  subscriptionTypes: {
    value: string
    label: string
  }[]
}

export const AddSubscriptionModal = ({
  open,
  onConfirm,
  onCancel,
  defaultSelectedOrganization,
  subscriptionTypes,
}: Props) => {
  const [selectedOrganization, setSelectedOrganization] = React.useState<
    | {
        value: string
        label: string
      }
    | undefined
  >(defaultSelectedOrganization)
  const [selectedSubscriptionType, setSelectedSubscriptionType] =
    React.useState<string>('')
  const [selectedEffectiveDate, setSelectedEffectiveDate] =
    React.useState<Date>(dayjs().toDate())
  const [selectedExpiresDate, setSelectedExpirationDate] =
    React.useState<Date | null>(dayjs().add(1, 'year').toDate())

  const [organizationsRequest] = useAsync(async () => {
    if (!selectedOrganization) return
    return await getOrganizations()
  }, [defaultSelectedOrganization])

  const organizations = React.useMemo(() => {
    if (!organizationsRequest.result) return []
    return organizationsRequest.result
  }, [organizationsRequest.result])

  React.useEffect(() => {
    if (defaultSelectedOrganization) {
      setSelectedOrganization(defaultSelectedOrganization)
    }
  }, [defaultSelectedOrganization])

  return (
    <ConfirmationModal
      open={open}
      onConfirm={() => {
        if (!selectedOrganization) {
          return
        }
        onConfirm(
          selectedOrganization.value,
          selectedSubscriptionType,
          selectedEffectiveDate,
          selectedExpiresDate
        )
      }}
      onCancel={onCancel}
      title={
        defaultSelectedOrganization
          ? `Add Subscription for ${selectedOrganization?.label}`
          : 'Add Subscription'
      }
      content={
        <Stack spacing={1}>
          {!defaultSelectedOrganization && (
            <SubscriptionOrganizationSelect
              organizations={organizations}
              selectedOrganizationId={selectedOrganization?.value || ''}
              setSelectedOrganizationId={(value) =>
                setSelectedOrganization(
                  organizations.find((o) => o.value === value)
                )
              }
            />
          )}
          <SubscriptionSelect
            subscriptionTypes={subscriptionTypes}
            selectedSubscriptionType={selectedSubscriptionType}
            setSelectedSubscriptionType={setSelectedSubscriptionType}
          />
          <SubscriptionDateSelect
            title={'Effective Date'}
            selectedDate={selectedEffectiveDate}
            setSelectedDate={setSelectedEffectiveDate}
          />
          <SubscriptionDateSelect
            title={'Expiration Date'}
            selectedDate={selectedExpiresDate}
            setSelectedDate={setSelectedExpirationDate}
            allowEmpty
          />
        </Stack>
      }
    ></ConfirmationModal>
  )
}
