import React from 'react'
import { ConfirmationModal } from '../../../app/ConfirmationModal/ConfirmationModal'

interface Props {
  open: boolean
  onConfirm: () => void
  onCancel: () => void
  subscriptionType: string
  organizationName: string
}

export const DeleteSubscriptionModal = ({
  open,
  onConfirm,
  onCancel,
  subscriptionType,
  organizationName,
}: Props) => {
  return (
    <ConfirmationModal
      open={open}
      onConfirm={onConfirm}
      onCancel={onCancel}
      title={`Delete Subscription`}
      content={`Are you sure you want to delete ${subscriptionType} subscription for ${organizationName}?`}
    ></ConfirmationModal>
  )
}
