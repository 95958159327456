import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { Column } from '../../../../admin/UI/Column'
import warnConfirm from '../../../../admin/warnConfirm'
import AsyncSelectorStatusOverlay from '../../../../AsyncSelector/AsyncSelectorStatusOverlay'
import { selectMe } from '../../../../data/selectMe'
import { connect } from '../../../../redux/connect'
import { RootStore } from '../../../../redux/types'
import { NotificationButton } from '../../../../UI/NotificationButton'
import vvapi from '../../../../vvapi'
import { DeliveryGroupStatusView } from '../DeliveryGroupStatusView'
import { enableSelectedLayers } from '../enableLayers'
import { refreshGetDelivery, selectGetDelivery } from '../selectGetDelivery'
import {
  GetDetailedDeliveryStatus,
  GroupStatus,
  GroupStatuses,
  ParcelStatus,
  ParcelStatuses,
  refreshGetDetailedDeliveryStatus,
  selectGetDetailedDeliveryStatus,
  selectParcelFileStatusesByGroupByParcel,
} from '../selectGetDetailedDeliveryStatus'
import LayerStatus from './LayerStatus'
import {
  SendDeliveryDataAvailableDialog,
  SendDeliveryDataAvailableParams,
} from './SendDeliveryDataAvailableDialog'

interface State {
  selectedParcelFiles?: Record<number, Record<string, Record<string, boolean>>>
  isLoading: boolean
  sendDeliveryDataAvailableDialogOpen?: boolean
}

class DeliveryStatus extends React.Component<ReduxProps, State> {
  state: State = { isLoading: false }

  componentDidMount(): void {
    refreshGetDetailedDeliveryStatus()
  }

  render() {
    const { selectedParcelFiles } = this.state
    const { deliveryStatusSelector, selectedDeliveryId } = this.props
    const data = deliveryStatusSelector.data
    const { groups, enabled, fulfilled, ...status } = data ?? {
      groups: undefined,
      fulfilled: false,
      enabled: false,
      fulfilledCount: 0,
      enabledCount: 0,
      expectedCount: 0,
      totalCount: 0,
      missingCount: 0,
      extraCount: 0,
      readyCount: 0,
    }

    const selection = selectedParcelFiles ?? this.getLayerEnabledSelection()

    return (
      <AsyncSelectorStatusOverlay
        style={{ minHeight: 100 }}
        requests={deliveryStatusSelector}
      >
        <Column style={{ alignItems: 'stretch' }}>
          {this.renderNotificationButtons()}

          <div className="Paper" style={{ marginTop: 8 }}>
            <Stack spacing={1}>
              <Stack
                width="100%"
                direction="row"
                justifyContent="space-between"
              >
                <Typography variant="h6" style={{ paddingBottom: 12 }}>
                  Groups Statuses:
                </Typography>
                <div className="actions">
                  <Button variant="contained" onClick={this.refresh}>
                    Refresh Statuses
                  </Button>
                </div>
              </Stack>

              <Stack className="actions" direction="row" spacing={1}>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  disabled={!this.state.selectedParcelFiles}
                  onClick={() =>
                    this.setState({ selectedParcelFiles: undefined })
                  }
                >
                  Reset
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  disabled={!this.state.selectedParcelFiles}
                  onClick={() => this.handleSaveEnabledLayersSelection()}
                >
                  Save
                </Button>
              </Stack>
              <div className="grid">
                <div
                  style={{
                    gridColumn: '3 / span 8',
                  }}
                >
                  <Table style={{ tableLayout: 'fixed' }}>
                    <TableHead>
                      <TableCell>Group</TableCell>
                      <TableCell> Parcel</TableCell>
                      <TableCell colSpan={6}>Layer</TableCell>
                      <TableCell>Expected</TableCell>
                      <TableCell>Ready</TableCell>
                      <TableCell>Enabled</TableCell>
                    </TableHead>
                    <TableBody>
                      {(groups ?? []).map(
                        (g: UnwrapArray<GetDetailedDeliveryStatus['groups']>) =>
                          this.renderGroupStatus(g, selection)
                      )}
                    </TableBody>
                  </Table>
                </div>
              </div>

              <Stack className="actions" direction="row" spacing={1}>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  disabled={!this.state.selectedParcelFiles}
                  onClick={() =>
                    this.setState({ selectedParcelFiles: undefined })
                  }
                >
                  Reset
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  disabled={!this.state.selectedParcelFiles}
                  onClick={() => this.handleSaveEnabledLayersSelection()}
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </div>
          <LayerStatus
            onRefresh={this.refresh}
            deliveryId={selectedDeliveryId!}
            status={status}
          />
        </Column>
      </AsyncSelectorStatusOverlay>
    )
  }

  renderGroupStatus = (
    group: UnwrapArray<GetDetailedDeliveryStatus['groups']>,
    selection: Record<number, Record<string, Record<string, boolean>>>
  ) => {
    const groupSelection = selection[group.groupId]
    const { parcelFileStatusesByGroupByParcel } = this.props
    const { parcelFiles } = parcelFileStatusesByGroupByParcel[group.groupId]

    return (
      <DeliveryGroupStatusView
        key={group.groupId}
        group={group}
        parcelFiles={parcelFiles}
        selection={groupSelection}
        selectionChanged={this.handleSelectionChanged}
      />
    )
  }

  renderNotificationButtons = () => {
    const {
      deliverySelector: { data: delivery },
      user,
    } = this.props

    if (!delivery || !user?.roles.includes('admin')) {
      return null
    }

    const error =
      delivery.Organization.OrganizationUsers_aggregate.aggregate.count < 1
        ? `This delivery's organization has no active users`
        : undefined

    const sendFlightCompleteNotification = async () => {
      await vvapi.notification.sendFlightComplete(delivery.id)
      refreshGetDelivery()
    }

    const sendDataAvailableNotification = async (
      params: SendDeliveryDataAvailableParams
    ) => {
      await vvapi.notification.sendDataAvailable(delivery.id, params)
      refreshGetDelivery()
      this.setState({ sendDeliveryDataAvailableDialogOpen: false })
    }

    const handleSendDataAvailable = () => {
      this.setState({ sendDeliveryDataAvailableDialogOpen: true })
    }

    return (
      <>
        <SendDeliveryDataAvailableDialog
          open={Boolean(this.state.sendDeliveryDataAvailableDialogOpen)}
          showWarning={
            (this.props.deliveryStatusSelector.data?.missingCount ?? 0) > 0
          }
          onSubmit={sendDataAvailableNotification}
          onCancel={() =>
            this.setState({ sendDeliveryDataAvailableDialogOpen: false })
          }
          deliveryGroupStatuses={
            this.props.deliveryStatusSelector?.data?.groups ?? []
          }
        />
        <div className="Paper" style={{ marginTop: 8 }}>
          <Typography variant="h6" style={{ paddingBottom: 12 }}>
            Notifications:
          </Typography>
          <Stack direction="row" spacing={1}>
            <NotificationButton
              notificationName="Flight Complete"
              sentAt={delivery.flightCompleteSentAt}
              send={sendFlightCompleteNotification}
              error={error}
            />

            <NotificationButton
              notificationName="Data Available"
              sentAt={delivery.dataAvailableSentAt}
              send={handleSendDataAvailable}
              error={error}
              inhibitConfirm
            />
          </Stack>
        </div>
      </>
    )
  }

  refresh = async () => {
    const { selectedParcelFiles } = this.state
    if (
      !selectedParcelFiles ||
      (await warnConfirm({
        title: 'Confirm Refresh',
        message: 'Refreshing will discard your current edits. Are you sure?',
      }))
    ) {
      refreshGetDetailedDeliveryStatus()
    }
  }

  handleSaveEnabledLayersSelection = async () => {
    const { selectedParcelFiles } = this.state
    if (!selectedParcelFiles) {
      return
    }

    if (
      await warnConfirm({
        title: 'Confirm Layer Activation',
        message: 'Are you sure you would like to enable the selected layers?',
      })
    ) {
      this.setState({ isLoading: true }, async () => {
        const { selectedDeliveryId } = this.props

        const mapLayerIds = this.flattenMapLayerIds(selectedParcelFiles)
        await enableSelectedLayers(selectedDeliveryId!, mapLayerIds)

        refreshGetDetailedDeliveryStatus()

        this.setState({ isLoading: false, selectedParcelFiles: undefined })
      })
    }
  }

  // Flattens a Record<string, boolean>
  // to a string[] where the values in the array are the keys where the
  // corresponding value in the Record<string, boolean> are true
  flattenParcel = (parcel?: Record<string, boolean>) =>
    Object.entries(parcel ?? {}).reduce(
      (mapLayerIds, [mapLayerId, selected]) => {
        if (selected) {
          return [...(mapLayerIds ?? []), mapLayerId]
        }

        return mapLayerIds ?? []
      },
      [] as string[]
    )

  // Flattens a Record<string, Record<string, boolean>>
  // to a string[] where the values in the array are the keys where the
  // corresponding value in the Record<string, boolean> are true
  flattenGroup = (group?: Record<string, Record<string, boolean>>) =>
    Object.values(group ?? {}).reduce((mapLayerIds, parcel) => {
      return [...mapLayerIds, ...this.flattenParcel(parcel)]
    }, [] as string[])

  // Flattens a Record<number, Record<string, Record<string, boolean>>>
  // to a string[] where the values in the array are the keys where the
  // corresponding value in the Record<string, boolean> are true
  flattenMapLayerIds = (
    selectedParcelFiles?: Record<
      number,
      Record<string, Record<string, boolean>>
    >
  ) =>
    Object.values(selectedParcelFiles ?? {}).reduce((mapLayerIds, group) => {
      return [...mapLayerIds, ...this.flattenGroup(group)]
    }, [] as string[])

  getSelectedFileMap = (files?: ParcelStatus['files'], selected = true) =>
    (files ?? []).reduce((fileMap, file) => {
      if (!file.MapLayer) {
        return fileMap
      }

      return {
        ...fileMap,
        [file.MapLayer.id]: selected,
      }
    }, {} as Record<string, boolean>)

  getSelectedGroupMap = (group?: GroupStatus, selected = true) =>
    Object.entries(group?.parcelFiles ?? ({} as ParcelStatuses)).reduce(
      (parcels, [currentParcelName, { files }]) => {
        return {
          ...parcels,
          [currentParcelName]: this.getSelectedFileMap(files, selected),
        }
      },
      {} as Record<string, Record<string, boolean>>
    )

  handleSelectionChanged = (
    selected: boolean,
    groupId: string,
    parcelName?: string,
    layerName?: string
  ) => {
    const { selectedParcelFiles } = this.state
    const selection = selectedParcelFiles ?? this.getLayerEnabledSelection()

    const { parcelFileStatusesByGroupByParcel } = this.props
    if (parcelFileStatusesByGroupByParcel[groupId]) {
      if (!parcelName && !layerName) {
        const newSelectedParcelFiles = {
          ...selection,
          [groupId]: this.getSelectedGroupMap(
            parcelFileStatusesByGroupByParcel[groupId],
            selected
          ),
        }

        this.setState({ selectedParcelFiles: newSelectedParcelFiles })
      } else if (!layerName) {
        const { parcelFiles } = parcelFileStatusesByGroupByParcel[groupId]
        if (parcelFiles[parcelName!]) {
          const newSelectedParcelFiles = {
            ...selection,
            [groupId]: {
              ...(selection[groupId] ?? {}),
              [parcelName!]: this.getSelectedFileMap(
                parcelFiles[parcelName!].files,
                selected
              ),
            },
          }

          this.setState({ selectedParcelFiles: newSelectedParcelFiles })
        }
      } else {
        const newSelectedParcelFiles = {
          ...(selection ?? {}),
          [groupId]: {
            ...(selection[groupId] ?? {}),
            [parcelName!]: {
              ...((selection[groupId] ?? {})[parcelName!] ?? {}),
              [layerName]: selected,
            },
          },
        }

        this.setState({ selectedParcelFiles: newSelectedParcelFiles })
      }
    }
  }

  getSelectedMapLayers = (files: ParcelStatus['files']) =>
    files.reduce((fileMap, file) => {
      if (!file.MapLayer) {
        return fileMap
      }

      return {
        ...fileMap,
        [file.MapLayer.id]: file.MapLayer.enabled,
      }
    }, {} as Record<string, boolean>)

  getSelectedParcels = (parcelFiles: ParcelStatuses) =>
    Object.entries(parcelFiles).reduce((parcels, [parcelName, { files }]) => {
      return {
        ...parcels,
        [parcelName]: this.getSelectedMapLayers(files),
      }
    }, {} as Record<string, Record<string, boolean>>)

  getSelectedGroups = (parcelFileStatusesByGroupByParcel: GroupStatuses) =>
    Object.entries(parcelFileStatusesByGroupByParcel).reduce(
      (selection, [groupId, { parcelFiles }]) => {
        return {
          ...selection,
          [groupId]: this.getSelectedParcels(parcelFiles),
        }
      },
      {} as Record<number, Record<string, Record<string, boolean>>>
    )

  getLayerEnabledSelection = () => {
    const { parcelFileStatusesByGroupByParcel } = this.props

    return this.getSelectedGroups(parcelFileStatusesByGroupByParcel)
  }
}

const mapState = (state: RootStore) => ({
  selectedDeliveryId: state.router.params.deliveryId,
  deliverySelector: selectGetDelivery(state),
  deliveryStatusSelector: selectGetDetailedDeliveryStatus(state),
  parcelFileStatusesByGroupByParcel:
    selectParcelFileStatusesByGroupByParcel(state),
  user: selectMe(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}>(mapState)(DeliveryStatus)
