import * as React from 'react'

import {
  Box,
  Button,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  IconProps,
  InputAdornment,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import { ConfirmationModal } from '../app/ConfirmationModal/ConfirmationModal'
import AsyncSelectorStatusOverlay from '../AsyncSelector/AsyncSelectorStatusOverlay'
import { selectOrganization } from '../data/selectOrganization'
import { client, gql } from '../graphql/client'
import { useRedux } from '../hooks/useRedux'
import i18n, { keys } from '../i18n'
import { useNoteTemplates } from '../notes/NotesContext'
import { showFeedback } from '../redux/notifications/redux'
import { dispatch } from '../redux/store'
import fuzzySearch from '../util/fuzzySearch'
import { NoteFormV2 } from '../vvapi/models'
import { NoteTemplateForm } from './NoteTemplateForm'
import { actions } from './redux'
import { NoteTemplateSort } from './types'

const SortableTableCell = styled(TableCell)({
  cursor: 'pointer',
  userSelect: 'none',
})

interface SortableTableCellIconProps extends IconProps {
  direction: NoteTemplateSort['direction']
}
const SortableTableCellIcon = styled(Icon)(
  (props: SortableTableCellIconProps) => ({
    transform: props.direction === 'asc' ? 'rotateX(180deg)' : undefined,
  })
)

type NoteFormList = Pick<
  NoteFormV2,
  'id' | 'name' | 'createdAt' | 'pinColor' | 'createdBy' | 'fields'
>

interface Props {
  open: boolean
  loading: boolean
  errorLoading?: boolean
  templates?: NoteFormList[]
  sort?: NoteTemplateSort
  onDone: () => void
  onCreateNewTemplate: (template: NoteFormList) => Promise<void>
  onEditTemplate: (template: NoteFormList, id: string) => Promise<void>
  onDeleteTemplate: (id: string) => Promise<void>
  onFilterTextChanged: (filterText: string) => void
  sortByColumn: (column: NoteTemplateSort['column']) => void
}

export const NoteTemplates = ({
  open,
  loading,
  errorLoading,
  onCreateNewTemplate,
  onEditTemplate,
  onDeleteTemplate,
  onFilterTextChanged,
  onDone,
  templates = [],
  sortByColumn,
  sort,
}: Props) => {
  const theme = useTheme()

  const isSm = useMediaQuery(theme.breakpoints.down('sm'))

  const [isCreateDialogOpen, setIsCreateDialogOpen] = React.useState(false)
  const [filterText, setFilterText] = React.useState<string | undefined>()
  const [editingTemplate, setEditingTemplate] = React.useState<
    NoteFormV2 | undefined
  >()
  const [deletingTemplateId, setDeletingTemplateId] = React.useState<
    string | undefined
  >()

  const handleOpenCreateNewTemplate = () => {
    setIsCreateDialogOpen(true)
  }

  const handleCreateNewTemplate = async (template: NoteFormV2, id?: string) => {
    if (id) {
      await onEditTemplate(template, id)
      setEditingTemplate(undefined)
      setIsCreateDialogOpen(false)
    } else {
      await onCreateNewTemplate(template)
      setIsCreateDialogOpen(false)
    }
  }

  const handleDeleteClicked = (id: string) => {
    setDeletingTemplateId(id)
  }

  const handleDelete = React.useCallback(async () => {
    try {
      await onDeleteTemplate(deletingTemplateId!)
    } catch (e) {
      console.error(e)
      dispatch(
        showFeedback({
          message: i18n.t(keys.noteForm.errors.errorDeleting),
          severity: 'error',
        })
      )
    }
    setDeletingTemplateId(undefined)
  }, [deletingTemplateId, onDeleteTemplate])

  const handleDone = () => {
    setTimeout(() => {
      setFilterText(undefined)
      setEditingTemplate(undefined)
    }, theme.transitions.duration.leavingScreen)

    onDone()
  }

  const handleCancel = () => {
    setTimeout(() => {
      setFilterText(undefined)
      setEditingTemplate(undefined)
    }, theme.transitions.duration.leavingScreen)

    setIsCreateDialogOpen(false)
  }

  React.useEffect(() => {
    onFilterTextChanged(filterText ?? '')
  }, [onFilterTextChanged, filterText])

  return (
    <>
      <NoteTemplateForm
        open={isCreateDialogOpen}
        onCancel={handleCancel}
        onSubmit={handleCreateNewTemplate}
        initialTemplate={editingTemplate}
      />
      <Dialog open={open} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h5">
                {i18n.t(keys.noteForm.manage.title)}
              </Typography>
              <IconButton
                onClick={handleDone}
                aria-label="close"
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                <Icon>close</Icon>
              </IconButton>
            </Stack>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              sx={{ width: '100%' }}
            >
              <Icon
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: '30px !important',
                  mr: '4px',
                }}
              >
                info
              </Icon>
              <Typography
                variant="subtitle1"
                sx={{ color: theme.palette.primary.main }}
              >
                {i18n.t(keys.noteForm.manage.info)}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="end"
              style={{ marginTop: 0 }}
            >
              <TextField
                placeholder={i18n.t(keys.noteForm.manage.templateNameFilter)}
                variant="outlined"
                value={filterText ?? ''}
                onChange={(ev) => setFilterText(ev.target.value)}
                sx={{
                  p: 1,
                  '& fieldset': {
                    borderWidth: 0,
                    background: theme.palette.background.default,
                  },
                  '& .MuiInputAdornment-root': {
                    zIndex: 2,
                  },
                  '& .MuiInputBase-input': {
                    p: 1,
                    zIndex: 2,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>search</Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ px: 1 }}>
          <AsyncSelectorStatusOverlay
            requests={[]}
            isLoading={loading}
            style={{ width: '100%' }}
          >
            {errorLoading ? (
              <Stack width="100%" alignItems="center">
                <Typography variant="caption" color="error">
                  {i18n.t(keys.noteForm.failedToLoad)}
                </Typography>
              </Stack>
            ) : (
              <Table
                size="small"
                sx={{ width: '100%', minWidth: 0, tableLayout: 'fixed' }}
              >
                <TableHead>
                  <SortableTableCell onClick={() => sortByColumn('name')}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ width: '100%' }}
                    >
                      {i18n.t(keys.noteForm.manage.columns.templateName)}
                      {sort?.column === 'name' ? (
                        <SortableTableCellIcon direction={sort.direction}>
                          notes
                        </SortableTableCellIcon>
                      ) : null}
                    </Stack>
                  </SortableTableCell>
                  <SortableTableCell onClick={() => sortByColumn('createdAt')}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ width: '100%' }}
                    >
                      {i18n.t(keys.noteForm.manage.columns.createdDate)}
                      {sort?.column === 'createdAt' ? (
                        <SortableTableCellIcon direction={sort.direction}>
                          notes
                        </SortableTableCellIcon>
                      ) : null}
                    </Stack>
                  </SortableTableCell>
                  <SortableTableCell onClick={() => sortByColumn('createdBy')}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ width: '100%' }}
                    >
                      {i18n.t(keys.noteForm.manage.columns.createdBy)}
                      {sort?.column === 'createdBy' ? (
                        <SortableTableCellIcon direction={sort.direction}>
                          notes
                        </SortableTableCellIcon>
                      ) : null}
                    </Stack>
                  </SortableTableCell>
                  <TableCell></TableCell>
                </TableHead>
                <TableBody sx={{ width: '100%', minWidth: 0 }}>
                  {templates?.map((template) => (
                    <TableRow
                      key={template.id}
                      sx={{ width: '100%', minWidth: 0 }}
                    >
                      <TableCell>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Box
                            sx={{
                              minHeight: 12,
                              minWidth: 12,
                              borderRadius: 12,
                              background: `${template.pinColor}`,
                            }}
                          />
                          <div>{template.name}</div>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        {i18n.toDateShort(template.createdAt!)}
                      </TableCell>
                      <TableCell>
                        {template.createdBy
                          ? `${template.createdBy.firstName} ${template.createdBy.lastName}`
                          : ''}
                      </TableCell>
                      <TableCell align="right">
                        <Stack spacing={1} direction="row" justifyContent="end">
                          <IconButton
                            onClick={() => {
                              setEditingTemplate(template)
                              setIsCreateDialogOpen(true)
                            }}
                          >
                            <Icon>edit</Icon>
                          </IconButton>
                          <IconButton
                            onClick={() => handleDeleteClicked(template.id)}
                          >
                            <Icon>delete</Icon>
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </AsyncSelectorStatusOverlay>
        </DialogContent>
        <DialogActions>
          <Stack
            direction={isSm ? 'column-reverse' : 'row'}
            justifyItems="stretch"
            sx={{ width: '100%' }}
            spacing={1}
          >
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              size="large"
              onClick={handleDone}
            >
              {i18n.t(keys.forms.done)}
            </Button>
            <Button
              size="large"
              variant="contained"
              fullWidth
              color="primary"
              disableElevation
              onClick={handleOpenCreateNewTemplate}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Icon>add_circle_outline</Icon>
                <Typography>
                  {i18n.t(keys.noteForm.manage.newTemplate)}
                </Typography>
              </Stack>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <ConfirmationModal
        title={i18n.t(keys.noteForm.manage.dialogs.delete.title)}
        content={i18n.t(keys.noteForm.manage.dialogs.delete.message)}
        open={!!deletingTemplateId}
        onConfirm={handleDelete}
        onCancel={() => setDeletingTemplateId(undefined)}
      />
    </>
  )
}

export const MappedNoteTemplates = () => {
  const [templates, refreshTemplates] = useNoteTemplates()
  const [state, dispatch] = useRedux()

  const organization = selectOrganization(state)

  const [filterText, setFilterText] = React.useState<string>('')

  const sort = state.noteForm.sort
  const isManageFormsVisible = React.useMemo(
    () => state.noteForm.isManageFormsVisible,
    [state]
  )

  const filteredTemplates = React.useMemo(
    () =>
      sort
        ? Object.values(templates.result ?? {})
            ?.filter(
              (noteForm) =>
                noteForm.organizationId !== null &&
                fuzzySearch(filterText, noteForm.name)
            )
            .sort((a, b) => {
              if (sort.column === 'createdBy') {
                if (sort.direction === 'asc') {
                  return `${a.createdBy?.firstName} ${a.createdBy?.lastName}`.localeCompare(
                    `${b.createdBy?.firstName} ${b.createdBy?.lastName}`
                  )
                } else {
                  return -`${a.createdBy?.firstName} ${a.createdBy?.lastName}`.localeCompare(
                    `${b.createdBy?.firstName} ${b.createdBy?.lastName}`
                  )
                }
              }
              if (sort.direction === 'asc') {
                return (
                  a[sort.column]
                    ?.toString()
                    .localeCompare(b[sort.column]?.toString() ?? '') ?? 1
                )
              } else {
                return -(
                  a[sort.column]
                    ?.toString()
                    .localeCompare(b[sort.column]?.toString() ?? '') ?? 1
                )
              }
            })
        : Object.values(templates.result ?? {})?.filter(
            (noteForm) =>
              noteForm.organizationId !== null &&
              fuzzySearch(filterText, noteForm.name)
          ),

    [templates, filterText, sort]
  )

  const handleEditTemplate = async (template: NoteFormList, id: string) => {
    await client.request({
      query: gql`
        mutation UPDATE_NOTE_FORM($id: uuid!, $template: NoteForm_set_input!) {
          update_NoteForm_by_pk(pk_columns: { id: $id }, _set: $template) {
            id
          }
        }
      `,
      variables: { id, template },
    })

    refreshTemplates()
  }

  const handleDeleteTemplate = async (id: string) => {
    await client.request({
      query: gql`
        mutation SOFT_DELETE_NOTE_FORM($id: uuid!) {
          update_NoteForm_by_pk(
            pk_columns: { id: $id }
            _set: { deletedAt: "now()" }
          ) {
            id
          }
        }
      `,
      variables: { id },
    })

    refreshTemplates()
  }

  const handleCreateTemplate = async (template: NoteFormList) => {
    if (!organization) {
      return
    }

    await client.request({
      query: gql`
        mutation INSERT_NOTE_FORM($template: NoteForm_insert_input!) {
          insert_NoteForm_one(object: $template) {
            id
          }
        }
      `,
      variables: {
        template: { ...template, organizationId: organization.id },
      },
    })

    refreshTemplates()
  }
  const handleFilterTextChanged = debounce(setFilterText)

  const handleDone = () => {
    dispatch(actions.toggleIsManageFormsVisible(null))
  }

  const handleSortByColumn = (column: NoteTemplateSort['column']) => {
    dispatch(actions.setSortColumn(column))
  }

  return (
    <NoteTemplates
      loading={templates.status === 'pending'}
      errorLoading={!!templates.error}
      open={isManageFormsVisible}
      templates={filteredTemplates}
      onEditTemplate={handleEditTemplate}
      onCreateNewTemplate={handleCreateTemplate}
      onDeleteTemplate={handleDeleteTemplate}
      onFilterTextChanged={handleFilterTextChanged}
      sortByColumn={handleSortByColumn}
      onDone={handleDone}
      sort={sort}
    />
  )
}
