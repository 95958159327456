import { Autocomplete, FormControl, TextField, Typography } from '@mui/material'
import React from 'react'
import { indexArray } from '../../../util/indexArray'

interface AddSubscriptionFormProps {
  organizations: {
    value: string
    label: string
  }[]
  selectedOrganizationId: string
  setSelectedOrganizationId: (value: string) => void
}

export const SubscriptionOrganizationSelect = ({
  organizations,
  selectedOrganizationId,
  setSelectedOrganizationId,
}: AddSubscriptionFormProps) => {
  const organizationsById = React.useMemo(
    () => indexArray(organizations, 'value'),
    [organizations]
  )
  return (
    <FormControl>
      <Typography>Organization</Typography>
      <Autocomplete
        value={organizationsById[selectedOrganizationId]}
        options={organizations}
        getOptionLabel={(option) => option.label}
        onChange={(_, v) => setSelectedOrganizationId(v?.value || '')}
        renderInput={(params) => (
          <TextField {...params} label="Select Organization..." />
        )}
      />
    </FormControl>
  )
}
