import { client, gql } from '../graphql/client'
import { Subscription, SubscriptionFeature } from '../graphql/types'
import { indexArray } from '../util/indexArray'

const getOrganizationSubscriptionsQuery = gql`
  query getOrganizationSubscriptions($organizationId: Int!) {
    Subscriptions: Subscription(
      order_by: { SubscriptionType: { priority: asc } }
      where: {
        _and: [
          { organizationId: { _eq: $organizationId } }
          { deletedAt: { _is_null: true } }
          {
            _or: [
              { expiresAt: { _is_null: true } }
              { expiresAt: { _gte: "now()" } }
            ]
          }
        ]
      }
    ) {
      id
      organizationId
      effectiveAt
      expiresAt
      subscriptionTypeId
      SubscriptionType {
        id
        name
        description
        SubscriptionFeatures {
          subscriptionTypeId
          subscriptionFeatureTypeId
          SubscriptionFeatureType {
            name
            description
          }
          limit
          limitTypeId
        }
      }
    }
  }
`

const getOrganizationSubscriptionFeaturesQuery = gql`
  query getOrganizationSubscriptionFeatures($organizationId: Int!) {
    OrganizationSubscriptionFeatures: View_OrganizationSubscriptionFeatures(
      where: { organizationId: { _eq: $organizationId } }
    ) {
      id
      organizationId
      subscriptionFeatureTypeId
      subscriptionTypeId
      limit
      limitTypeId
    }
  }
`

const getAdminSubscriptionFeaturesQuery = gql`
  query getAdminSubscriptionFeatures {
    OrganizationSubscriptionFeatures: View_AdminSubscriptionFeatures {
      id
      subscriptionFeatureTypeId
      subscriptionTypeId
      limit
      limitTypeId
    }
  }
`

interface GetOrganizationSubscriptionInfoParams {
  organizationId: string
  isAdmin?: boolean
}

interface GetOrganizationSubscriptionFeatureResponse {
  OrganizationSubscriptionFeatures: SubscriptionFeature[]
}

interface GetOrganizationSubscriptionsResponse {
  Subscriptions: Subscription[]
}

const getOrganizationSubscriptionsAndFeatures = async ({
  organizationId,
  isAdmin = false,
}: GetOrganizationSubscriptionInfoParams) => {
  const { Subscriptions } =
    await client.request<GetOrganizationSubscriptionsResponse>({
      query: getOrganizationSubscriptionsQuery,
      variables: {
        organizationId,
      },
    })

  if (isAdmin) {
    const { OrganizationSubscriptionFeatures: AdminSubscriptionFeatures } =
      await client.request<GetOrganizationSubscriptionFeatureResponse>({
        query: getAdminSubscriptionFeaturesQuery,
      })

    return {
      prioritizedSubscriptionFeatures: indexArray(
        AdminSubscriptionFeatures,
        'subscriptionFeatureTypeId'
      ),
      subscriptions: Subscriptions,
    }
  }
  const { OrganizationSubscriptionFeatures } =
    await client.request<GetOrganizationSubscriptionFeatureResponse>({
      query: getOrganizationSubscriptionFeaturesQuery,
      variables: {
        organizationId,
      },
    })

  return {
    prioritizedSubscriptionFeatures: indexArray(
      OrganizationSubscriptionFeatures,
      'subscriptionFeatureTypeId'
    ),
    subscriptions: Subscriptions,
  }
}

export { getOrganizationSubscriptionsAndFeatures }
