import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import * as React from 'react'
import AsyncSelectorStatusOverlay from '../AsyncSelector/AsyncSelectorStatusOverlay'
import { selectDeliveryFilter } from '../data/selectDeliveryFilter'
import { selectDateMenuData, selectOrgMapData } from '../data/selectOrgMapData'
import * as userSelection from '../data/userSelectionRedux'
import { useRedux } from '../hooks/useRedux'
import i18n, { keys } from '../i18n'
import { toggleSubscriptionDialog } from '../subscriptions/actions'
import useSubscriptionFeature from '../subscriptions/hooks/useSubscriptionFeature'
import { SubscriptionLock } from '../subscriptions/SubscriptionLock'
import { dateOlderThanLimit } from '../subscriptions/util/limit'
import { splitFilter } from '../util/splitFilter'

const DateMenuItem = ({
  flightDate,
  disabled,
}: {
  flightDate: string
  disabled?: boolean
}) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    gap={1}
  >
    <Typography
      variant="subtitle1"
      align="left"
      color={!!disabled ? 'text.disabled' : 'text.primary'}
    >
      {flightDate}
    </Typography>
    <SubscriptionLock locked={!!disabled} />
  </Stack>
)

const DateMenu: React.FC = () => {
  const [state, dispatch] = useRedux()

  const selectedFlightDate = state.userSelection.selectedFlightDate
  const dates = selectDateMenuData(state)
  const deliveryFlightDatesSelector = selectOrgMapData(state)
  const deliveryFilter = selectDeliveryFilter(state)

  const { limit: flightDateLimitInYears } = useSubscriptionFeature({
    featureType: 'historical-data',
  })

  const [lockedSelectedDates, limitedSelectedDates] = React.useMemo(
    () =>
      splitFilter(dates.selectedDates, (date) =>
        dateOlderThanLimit(date, flightDateLimitInYears ?? 0)
      ),
    [dates.selectedDates, flightDateLimitInYears]
  )

  const [lockedOtherDates, limitedOtherDates] = React.useMemo(
    () =>
      splitFilter(dates.otherDates, (date) =>
        dateOlderThanLimit(date, flightDateLimitInYears ?? 0)
      ),
    [dates.otherDates, flightDateLimitInYears]
  )

  // If the current selected flight date becomes locked, unselect it.
  React.useMemo(() => {
    const allLockedDates = [...lockedSelectedDates, ...lockedOtherDates]

    if (selectedFlightDate && allLockedDates.includes(selectedFlightDate)) {
      dispatch(
        userSelection.update({
          selectedFlightDate: '',
        })
      )
    }
  }, [lockedSelectedDates, lockedOtherDates, selectedFlightDate, dispatch])

  const handleOpenSubscriptionDialog = React.useCallback(
    () => dispatch(toggleSubscriptionDialog(true)),
    [dispatch]
  )

  const handleChange = React.useCallback(
    (event: SelectChangeEvent) => {
      if (
        lockedOtherDates.includes(event.target.value) ||
        lockedSelectedDates.includes(event.target.value)
      ) {
        handleOpenSubscriptionDialog()
        return
      }
      dispatch(
        userSelection.update({
          selectedFlightDate: event.target.value,
        })
      )
    },
    [
      lockedOtherDates,
      lockedSelectedDates,
      dispatch,
      handleOpenSubscriptionDialog,
    ]
  )

  return (
    <div>
      <AsyncSelectorStatusOverlay
        requests={[deliveryFlightDatesSelector, deliveryFilter]}
      >
        <Select
          disabled={
            dates.selectedDates.length === 0 && dates.otherDates.length === 0
          }
          value={selectedFlightDate ?? ''}
          onChange={handleChange}
        >
          {dates.selectedDates.length > 0 && [
            <MenuItem disabled key="for-selected">
              <Typography variant="subtitle1" align="left">
                {i18n.t(keys.forSelectedParcels)}
              </Typography>
            </MenuItem>,
            <hr key="for-selected-separator" />,
          ]}
          {limitedSelectedDates.map((flightDate) => (
            <MenuItem key={flightDate} value={flightDate}>
              <DateMenuItem flightDate={flightDate} />
            </MenuItem>
          ))}
          {lockedSelectedDates.map((flightDate) => (
            <MenuItem key={flightDate} value={flightDate}>
              <DateMenuItem flightDate={flightDate} disabled />
            </MenuItem>
          ))}
          {dates.otherDates.length > 0 && [
            <MenuItem disabled key="for-other-parcels">
              <Typography variant="subtitle1" align="left">
                {i18n.t(keys.forOtherParcels)}
              </Typography>
            </MenuItem>,
            <hr key="for-other-separator" />,
          ]}
          {limitedOtherDates.map((flightDate) => (
            <MenuItem key={flightDate} value={flightDate}>
              <DateMenuItem flightDate={flightDate} />
            </MenuItem>
          ))}
          {lockedOtherDates.map((flightDate) => (
            <MenuItem key={flightDate} value={flightDate}>
              <DateMenuItem flightDate={flightDate} disabled />
            </MenuItem>
          ))}
        </Select>
      </AsyncSelectorStatusOverlay>
    </div>
  )
}

export default DateMenu
