import React from 'react'
import { SubscriptionFeatureTypes } from '../../graphql/types'
import { useRedux } from '../../hooks/useRedux'

interface Inputs {
  featureType: SubscriptionFeatureTypes
}

interface Outputs {
  enabled: boolean
  limit?: number
}

const useSubscriptionFeature = ({ featureType }: Inputs): Outputs => {
  const [state] = useRedux()
  const subscriptionFeatures = React.useMemo(
    () => state?.subscription?.subscriptionFeatures ?? {},
    [state.subscription.subscriptionFeatures]
  )
  const subscriptionFeature = React.useMemo(
    () =>
      subscriptionFeatures[featureType] ?? {
        limit: 'false',
        limitTypeId: 'access',
      },
    [subscriptionFeatures, featureType]
  )
  if (subscriptionFeature.limitTypeId === 'access') {
    return {
      enabled: subscriptionFeature.limit === 'true',
    }
  }
  return {
    enabled: true,
    limit:
      subscriptionFeature.limit === 'unlimited'
        ? Number.POSITIVE_INFINITY
        : Number(subscriptionFeature.limit),
  }
}

export default useSubscriptionFeature
