import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import * as React from 'react'
import i18n, { keys } from '../../i18n'

interface Props {
  open: boolean
  onCancel?: () => void
  onConfirm: () => void
  title: string | React.ReactNode
  content: string | React.ReactNode
  dangerMode?: boolean
  messageStyle?: React.CSSProperties
  buttonVariant?: 'text' | 'outlined' | 'contained'
  buttonText?: string
}

const Title = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.15px;
  text-align: left;
`

const Description = styled(Typography)<{ customStyle?: React.CSSProperties }>`
  font-size: 16px;
  font-weight: normal;
  line-height: 19.2px;
  letter-spacing: -0.035em;
  text-align: left;
  ${(props) => props.customStyle && { ...props.customStyle }}
`

export const ConfirmationModal = ({
  open,
  onCancel,
  onConfirm,
  title,
  content,
  dangerMode,
  messageStyle,
  buttonVariant,
  buttonText,
}: Props) => {
  const theme = useTheme()
  return (
    <Dialog open={open}>
      <DialogTitle>
        {typeof title === 'string' ? <Title>{title}</Title> : title}
      </DialogTitle>
      <Divider />
      <DialogContent>
        {typeof content === 'string' ? (
          <Description customStyle={messageStyle}>{content}</Description>
        ) : (
          content
        )}
      </DialogContent>
      <DialogActions>
        <Button
          style={{ color: theme.palette.text.primary }}
          onClick={onCancel}
        >
          {i18n.t(keys.generic.cancel)}
        </Button>
        <Button
          color={dangerMode ? 'error' : 'primary'}
          onClick={onConfirm}
          variant={buttonVariant}
        >
          {buttonText ||
            (dangerMode
              ? i18n.t(keys.generic.delete)
              : i18n.t(keys.generic.confirm))}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
