import { Stack, styled } from '@mui/material'
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
} from 'material-react-table'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { ConfirmationModal } from '../../../app/ConfirmationModal/ConfirmationModal'
import { InputModal } from '../../../app/InputModal/InputModal'
import Page from '../../../app/Page'
import { url, urls } from '../../../appNavigation/urls'
import { selectOrganizationId } from '../../../data/selectOrganizationId'
import { selectPreferredLanguage } from '../../../data/selectPreferredLanguage'
import { RateMap } from '../../../graphql/types'
import useAsync from '../../../hooks/useAsync'
import { useRedux } from '../../../hooks/useRedux'
import i18n, { keys } from '../../../i18n'
import ActionsCell from '../../../UI/MaterialReactTable/ActionsCell'
import { useDefaultTableConfig } from '../../../UI/MaterialReactTable/useDefaultTableConfig'
import {
  deleteRateMap,
  duplicateRateMap,
  getOrgFilterOptions,
  getRateMaps,
} from '../queries'
import { RateMapRow } from '../types'
import { RateMapActionMenuItems } from './RateMapActionMenuItems'
import { RateMapEmptyView } from './RateMapEmptyView'
import { RateMapToolBarOptions } from './RateMapToolBarOptions'

const TableContainer = styled(Stack)({
  margin: '30px 128px',
})

export const RateMapDashboard = () => {
  const [state] = useRedux()
  const history = useHistory()

  const preferredLanguage = selectPreferredLanguage(state)
  const organizationId = selectOrganizationId(state)
  const [rowCount, setRowCount] = React.useState(0)

  //table state
  const [columnFilters, setColumnFilters] =
    React.useState<MRT_ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [sorting, setSorting] = React.useState<MRT_SortingState>([])
  const [pagination, setPagination] = React.useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })

  const [filterOptions] = useAsync(async () => {
    return await getOrgFilterOptions({ organizationId })
  }, [organizationId])

  const amendmentFilterOptions = React.useMemo(() => {
    if (!filterOptions.result) return {}
    return filterOptions.result.amendmentOptions.map((a) => ({
      value: a.value,
      label: a.label[preferredLanguage],
    }))
  }, [filterOptions.result, preferredLanguage])

  const parcelFilterOptions = React.useMemo(() => {
    if (!filterOptions.result) return {}
    return filterOptions.result.parcelOptions
  }, [filterOptions.result])

  const propertyNameFilterOptions = React.useMemo(() => {
    if (!filterOptions.result) return {}
    return filterOptions.result.propertyNameOptions
  }, [filterOptions.result])

  const [rateMapRequest, refreshRateMapRequest] = useAsync(async () => {
    const { RateMaps, totalRowCount } = await getRateMaps({
      organizationId,
      columnFilters,
      globalFilter,
      sorting,
      pagination,
    })
    setRowCount(totalRowCount)
    return RateMaps
  }, [
    organizationId,
    columnFilters,
    globalFilter,
    sorting,
    pagination.pageIndex,
    pagination.pageSize,
  ])

  const [deleteCandidate, setDeleteCandidate] = React.useState<RateMap>()

  const [duplicateCandidate, setDuplicateCandidate] = React.useState<RateMap>()

  const handleDelete = async () => {
    if (!deleteCandidate) {
      return
    }
    await deleteRateMap({ rateMapId: deleteCandidate.id })
    refreshRateMapRequest()
    setDeleteCandidate(undefined)
  }

  const handleDuplicate = async (value: string) => {
    if (!duplicateCandidate) {
      return
    }

    const { RateMap: duplicate } = await duplicateRateMap({
      rateMap: duplicateCandidate,
      newName: value,
    })
    history.push(url(urls.rateMap, { rateMapId: duplicate.id }))
  }

  const sortedRateMaps = React.useMemo(() => {
    if (!rateMapRequest.result) return []

    let sortedData = [...rateMapRequest.result]

    const propertyNameSort = sorting.find((sort) => sort.id === 'propertyName')
    if (propertyNameSort) {
      sortedData.sort((a, b) => {
        const aValue = a.RateMapBlockSelections?.[0]?.Parcel?.Group?.name || ''
        const bValue = b.RateMapBlockSelections?.[0]?.Parcel?.Group?.name || ''
        return propertyNameSort.desc
          ? bValue.localeCompare(aValue)
          : aValue.localeCompare(bValue)
      })
    }

    const blockSort = sorting.find((sort) => sort.id === 'block')
    if (blockSort) {
      sortedData.sort((a, b) => {
        const aValue =
          a.RateMapBlockSelections?.map((b) => b?.Parcel?.name).join(', ') || ''
        const bValue =
          b.RateMapBlockSelections?.map((b) => b?.Parcel?.name).join(', ') || ''
        return blockSort.desc
          ? bValue.localeCompare(aValue)
          : aValue.localeCompare(bValue)
      })
    }

    return sortedData.map((rm) => ({
      id: rm.id,
      name: rm.name,
      createdAt: i18n.toDateShort(rm.createdAt),
      block:
        rm?.RateMapBlockSelections && rm.RateMapBlockSelections.length > 0
          ? rm.RateMapBlockSelections.map((b) => b?.Parcel?.name).join(', ')
          : '-',
      propertyName: rm?.groupName || '-',
      amendmentType: rm?.RateMapAmendmentType?.name[preferredLanguage] ?? '',
      edit: () => {
        history.push(url(urls.rateMap, { rateMapId: rm.id }))
      },
      duplicate: async () => {
        setDuplicateCandidate(rm)
      },
      delete: async () => {
        setDeleteCandidate(rm)
      },
    }))
  }, [rateMapRequest.result, sorting, preferredLanguage, history])

  const handleSortingChange = React.useCallback(
    (
      updaterOrValue:
        | MRT_SortingState
        | ((prevState: MRT_SortingState) => MRT_SortingState)
    ) => {
      setSorting((prev) => {
        const next =
          typeof updaterOrValue === 'function'
            ? updaterOrValue(prev)
            : updaterOrValue
        const hasClientSideSort = next.some((sort) =>
          ['block', 'propertyName'].includes(sort.id)
        )
        if (!hasClientSideSort) {
          refreshRateMapRequest()
        }
        return next
      })
    },
    [refreshRateMapRequest]
  )

  const caseInsensitiveSort = (
    rowA: { getValue: (arg0: any) => any },
    rowB: { getValue: (arg0: any) => any },
    columnId: any
  ) => {
    const valueA = String(rowA.getValue(columnId)).toLowerCase()
    const valueB = String(rowB.getValue(columnId)).toLowerCase()
    return valueA.localeCompare(valueB)
  }

  const columns = React.useMemo<MRT_ColumnDef<RateMapRow>[]>(() => {
    return [
      {
        accessorKey: 'name',
        header: i18n.t(keys.rateMapNameColumnLabel),
        sortingFn: caseInsensitiveSort,
      },
      {
        accessorKey: 'createdAt',
        header: i18n.t(keys.rateMapDateColumnLabel),
        filterVariant: 'date-range',
        size: 80,
      },
      {
        accessorKey: 'propertyName',
        header: i18n.t(keys.rateMapPropertyName),
        sortingFn: caseInsensitiveSort,
        filterVariant: 'multi-select',
        filterSelectOptions: propertyNameFilterOptions,
        size: 80,
      },
      {
        accessorKey: 'block',
        header: i18n.t(keys.rateMapBlockColumnLabel),
        filterVariant: 'multi-select',
        filterSelectOptions: parcelFilterOptions,
        size: 80,
      },
      {
        accessorKey: 'amendmentType',
        header: i18n.t(keys.rateMapAmendmentTypeColumnLabel),
        sortingFn: caseInsensitiveSort,
        filterVariant: 'multi-select',
        filterSelectOptions: amendmentFilterOptions,
      },
      {
        id: 'actions',
        header: 'Actions',
        Cell: ({ row, table }) => (
          <ActionsCell
            row={row}
            table={table}
            editTitle={i18n.t(keys.results)}
            menuItems={RateMapActionMenuItems}
          />
        ),
        minSize: 130,
        maxSize: 130,
        size: 130,
        grow: false,
      },
    ] as MRT_ColumnDef<RateMapRow>[]
  }, [amendmentFilterOptions, parcelFilterOptions, propertyNameFilterOptions])

  const table = useDefaultTableConfig<RateMapRow>({
    status: rateMapRequest.status,
    columns,
    data: sortedRateMaps,
    requiredTableConfig: {
      renderEmptyRowsFallback: RateMapEmptyView,
      rowCount,
      state: {
        columnFilters,
        globalFilter,
        isLoading: rateMapRequest.status === 'pending',
        pagination,
        showAlertBanner: rateMapRequest.status === 'rejected',
        sorting,
      },
      onColumnFiltersChange: setColumnFilters,
      onGlobalFilterChange: setGlobalFilter,
      onPaginationChange: setPagination,
      onSortingChange: handleSortingChange,
    },
    extendedTableConfig: {
      renderTopToolbarCustomActions:
        sortedRateMaps.length > 0 ? RateMapToolBarOptions : undefined,
      renderRowActionMenuItems: RateMapActionMenuItems,
    },
  })

  return (
    <Page
      title={i18n.t(keys.rateMap)}
      backTo={url(urls.mapView)}
      backToTitle={i18n.t(keys.map.map)}
    >
      <TableContainer>
        <MaterialReactTable table={table} />
      </TableContainer>
      <ConfirmationModal
        open={!!deleteCandidate}
        onConfirm={handleDelete}
        onCancel={() => setDeleteCandidate(undefined)}
        title={i18n.t(keys.rateMapDelete)}
        content={i18n.t(keys.rateMapDeleteConfirmation)}
        messageStyle={{ fontWeight: 'normal' }}
        dangerMode
      />
      <InputModal
        open={!!duplicateCandidate}
        onConfirm={handleDuplicate}
        onCancel={() => setDuplicateCandidate(undefined)}
        title={i18n.t(keys.rateMapDuplicate)}
        message={i18n.t(keys.rateMapDuplicateConfirmation)}
        defaultValue={`${duplicateCandidate?.name} - Copy`}
      />
    </Page>
  )
}
