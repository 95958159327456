import * as React from 'react'
import AsyncSelectorStatusOverlay from '../../../../AsyncSelector/AsyncSelectorStatusOverlay'
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { useRedux } from '../../../../hooks/useRedux'
import {
  DeliveryGroupDate,
  selectDateMenuDataWithFilter,
  selectOrgMapData,
} from '../../../../data/selectOrgMapData'
import { deliveryFilterFetcher } from '../../../../data/selectDeliveryFilter'
import i18n, { keys } from '../../../../i18n'
import { selectDrawerOption } from '../../../../map-editor/redux/selectors/selectDrawerOptions'
import { MapEditorToolType } from '../../../../redux/map-editor-pages/redux'
import { getFiltersInfo } from '../../../../data/selectParcelFilterWhere'
import { ParcelFilterInfo } from '../../../../postgis/types'
import { selectOrganizationId } from '../../../../data/selectOrganizationId'
import useAsync from '../../../../hooks/useAsync'
import useSubscriptionFeature from '../../../../subscriptions/hooks/useSubscriptionFeature'
import { splitFilter } from '../../../../util/splitFilter'
import { dateOlderThanLimit } from '../../../../subscriptions/util/limit'
import { SubscriptionLock } from '../../../../subscriptions/SubscriptionLock'
import { toggleSubscriptionDialog } from '../../../../subscriptions/actions'

const DateMenuItem = ({
  flightDate,
  locked: disabled,
}: {
  flightDate: string
  locked?: boolean
}) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    gap={1}
  >
    <Typography
      variant="subtitle1"
      align="left"
      color={!!disabled ? 'text.disabled' : 'text.primary'}
    >
      {flightDate}
    </Typography>
    <SubscriptionLock locked={!!disabled} />
  </Stack>
)

interface Props {
  selectedFlightDate: string
  onSelectedFlightDateChange: (flightDate: string) => void
  page: MapEditorToolType
  blockSelectorId: string
  selectedParcelIds: Set<string>
  disabled?: boolean
  dateFilter: (deliveryGroupDate: DeliveryGroupDate) => boolean
}

const FlightDateSelector = ({
  selectedFlightDate,
  onSelectedFlightDateChange,
  page,
  blockSelectorId,
  selectedParcelIds,
  disabled,
  dateFilter,
}: Props) => {
  const [state, dispatch] = useRedux()
  const { limit: flightDateLimitInYears } = useSubscriptionFeature({
    featureType: 'historical-data',
  })
  const organizationId = selectOrganizationId(state)
  const dates = selectDateMenuDataWithFilter(state, dateFilter)

  const [lockedSelectedDates, limitedSelectedDates] = React.useMemo(
    () =>
      splitFilter(dates.selectedDates, (date) =>
        dateOlderThanLimit(date, flightDateLimitInYears ?? 0)
      ),
    [dates.selectedDates, flightDateLimitInYears]
  )

  const [lockedOtherDates, limitedOtherDates] = React.useMemo(
    () =>
      splitFilter(dates.otherDates, (date) =>
        dateOlderThanLimit(date, flightDateLimitInYears ?? 0)
      ),
    [dates.otherDates, flightDateLimitInYears]
  )

  const [lockedFilteredDates, limitedFilteredDates] = React.useMemo(
    () =>
      splitFilter(dates.filteredDates, (date) =>
        dateOlderThanLimit(date, flightDateLimitInYears ?? 0)
      ),
    [dates.filteredDates, flightDateLimitInYears]
  )

  const deliveryFlightDatesSelector = selectOrgMapData(state)
  const option = selectDrawerOption(state, page, blockSelectorId)
  const parcelFiltersWhere = React.useMemo(
    () =>
      getFiltersInfo(
        option?.config?.parcelFilter?.metaFilters ??
          ({} as ParcelFilterInfo['metaFilters']),
        option?.config?.parcelFilter?.maxDate,
        option?.config?.parcelFilter?.minDate,
        option?.config?.parcelFilter?.products
      ),
    [option?.config?.parcelFilter]
  )

  const [deliveryFilterRequest] = useAsync(async () => {
    return deliveryFilterFetcher({
      organizationId,
      parcelFiltersWhere,
      selectedParcelIds,
    })
  }, [option?.config?.parcelFilter, parcelFiltersWhere])

  const handleOpenSubscriptionDialog = React.useCallback(
    () => dispatch(toggleSubscriptionDialog(true)),
    [dispatch]
  )

  const handleChange = (event: SelectChangeEvent<any>) => {
    if (
      lockedOtherDates.includes(event.target.value) ||
      lockedSelectedDates.includes(event.target.value) ||
      lockedFilteredDates.includes(event.target.value)
    ) {
      handleOpenSubscriptionDialog()
      return
    }
    onSelectedFlightDateChange(event.target.value)
  }

  return (
    <div>
      <AsyncSelectorStatusOverlay
        requests={[deliveryFlightDatesSelector, deliveryFilterRequest]}
      >
        <Select
          disabled={
            disabled ||
            (dates.selectedDates.length === 0 &&
              dates.otherDates.length === 0 &&
              dates.filteredDates.length === 0)
          }
          value={selectedFlightDate ?? 'select-date'}
          onChange={handleChange}
        >
          {dates.selectedDates.length > 0 && [
            <MenuItem disabled key="for-selected">
              <Typography variant="subtitle1" align="left">
                {i18n.t(keys.forSelectedParcels)}
              </Typography>
            </MenuItem>,
            <hr key="for-selected-separator" />,
          ]}
          {limitedSelectedDates.map((flightDate) => (
            <MenuItem key={flightDate} value={flightDate}>
              <DateMenuItem flightDate={flightDate} />
            </MenuItem>
          ))}
          {lockedSelectedDates.map((flightDate) => (
            <MenuItem key={flightDate} value={flightDate}>
              <DateMenuItem flightDate={flightDate} locked />
            </MenuItem>
          ))}
          {dates.otherDates.length > 0 && [
            <MenuItem disabled key="for-other-parcels">
              <Typography variant="subtitle1" align="left">
                {i18n.t(keys.forOtherParcels)}
              </Typography>
            </MenuItem>,
            <hr key="for-other-separator" />,
          ]}
          {limitedOtherDates.map((flightDate) => (
            <MenuItem key={flightDate} value={flightDate}>
              <DateMenuItem flightDate={flightDate} />
            </MenuItem>
          ))}
          {lockedOtherDates.map((flightDate) => (
            <MenuItem key={flightDate} value={flightDate}>
              <DateMenuItem flightDate={flightDate} locked />
            </MenuItem>
          ))}
          {lockedFilteredDates.length > 0 && [
            <MenuItem disabled key="unavailable-parcels">
              <Typography variant="subtitle1" align="left">
                {i18n.t(keys.generic.unavailable)}
              </Typography>
            </MenuItem>,
            <hr key="for-other-separator" />,
          ]}
          {limitedFilteredDates.map((flightDate) => (
            <MenuItem disabled key={flightDate} value={flightDate}>
              <DateMenuItem flightDate={flightDate} />
            </MenuItem>
          ))}
          {lockedFilteredDates.map((flightDate) => (
            <MenuItem disabled key={flightDate} value={flightDate}>
              <DateMenuItem flightDate={flightDate} locked />
            </MenuItem>
          ))}
        </Select>
      </AsyncSelectorStatusOverlay>
    </div>
  )
}

export default FlightDateSelector
