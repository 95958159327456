import { AnyAction } from 'redux'
import { SubscriptionState } from './types'
import {
  GET_SUBSCRIPTION_FEATURES_REQUEST,
  GET_SUBSCRIPTION_FEATURES_SUCCESS,
  GET_SUBSCRIPTION_FEATURES_FAILURE,
  TOGGLE_SUBSCRIPTION_DIALOG,
} from './actions'

export type SubscriptionActions = {
  type: 'toggleSubscripionDialog'
  isOpen: boolean
  getSubscriptionFeatures: 'getSubscriptionFeatures'
}

export const initialState: SubscriptionState = {
  isSubscriptionDialogOpen: false,
  selectedSubscription: null,
  subscriptions: [],
  subscriptionFeatures: {},
  selectedSubscriptionId: 'basic',
  isLoading: false,
  error: null,
}

export const reducer = (
  state: SubscriptionState = initialState,
  action: AnyAction
): SubscriptionState => {
  switch (action.type) {
    case TOGGLE_SUBSCRIPTION_DIALOG: {
      return {
        ...state,
        isSubscriptionDialogOpen: action.isOpen,
      }
    }
    case GET_SUBSCRIPTION_FEATURES_REQUEST:
      return { ...state, isLoading: true, error: null }
    case GET_SUBSCRIPTION_FEATURES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subscriptionFeatures: action.payload.features,
        selectedSubscription: action.payload.subscriptionTypeName,
        selectedSubscriptionId: action.payload.subscriptionTypeId,
        subscriptions: action.payload.subscriptions,
        error: null,
      }
    case GET_SUBSCRIPTION_FEATURES_FAILURE:
      return { ...state, isLoading: false, error: action.payload }
    default:
      return state
  }
}
