import * as React from 'react'

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { default as Table } from '@mui/material/Table'
import { default as TableBody } from '@mui/material/TableBody'
import { default as TableCell } from '@mui/material/TableCell'
import { default as TableHead } from '@mui/material/TableHead'
import { default as TableRow } from '@mui/material/TableRow'
import i18n, { keys } from '../i18n'
import { useRedux } from '../hooks/useRedux'
import { toggleSubscriptionDialog } from './actions'
import { styled } from '@mui/material/styles'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import EmailIcon from '@mui/icons-material/Email'

const StyledDialogTitle = styled(DialogTitle)(() => ({
  fontWeight: 800,
  fontSize: '30px',
  lineHeight: '36px',
  letterSpacing: '0.15px',
}))

const StyledDescription = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  maxWidth: '60%',
}))

const BoldSpan = styled('span')({
  fontWeight: 700,
})

const TableBodyText = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '21px',
}))

export const SubscriptionDialog = () => {
  const [state, dispatch] = useRedux()
  const open = state?.subscription?.isSubscriptionDialogOpen || false
  const subscriptionTypeId = state?.subscription?.selectedSubscriptionId

  const email = 'sales@vineview.com'
  const contactSales = i18n.t(keys.contactToUpgradeSubscription, {
    email: email,
  })
  const [before, after] = contactSales.split(email)

  const handleClose = () => {
    dispatch(toggleSubscriptionDialog(false))
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          position: 'relative',
          borderRadius: '24px',
          backgroundColor: '#303030',
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      {subscriptionTypeId === 'fieldsense' && (
        <StyledDialogTitle>
          {i18n.t(keys.subscriptionManagementTitle)}
        </StyledDialogTitle>
      )}
      {(subscriptionTypeId === 'basic' ||
        subscriptionTypeId === 'pinpoint') && (
        <StyledDialogTitle>
          {i18n.t(keys.subscriptionUnlockTitle)}
        </StyledDialogTitle>
      )}
      <DialogContent>
        {subscriptionTypeId === 'fieldsense' && (
          <StyledDescription
            paragraph
            variant="subtitle1"
            sx={{ maxWidth: '100%' }}
          >
            {i18n.t(keys.subscriptionManagementDescription)}
          </StyledDescription>
        )}
        {(subscriptionTypeId === 'basic' ||
          subscriptionTypeId === 'pinpoint') && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backgroundColor: '#5CC46C33',
              padding: '16px 16px 6px 16px',
              borderRadius: '8px',
              marginBottom: '16px',
            }}
          >
            <StyledDescription paragraph variant="subtitle1">
              {before}
              <BoldSpan>{email}</BoldSpan>
              {after}
            </StyledDescription>
            <Button
              variant="contained"
              sx={{
                border: '1px solid white',
                backgroundColor: '#5CC46C10',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                whiteSpace: 'nowrap',
                padding: '6px 16px 6px 12px',
                boxShadow: 'none',
              }}
              onClick={() => {
                window.open(
                  'https://vineview.com/connect/',
                  '_blank',
                  'noopener,noreferrer'
                )
              }}
            >
              <EmailIcon
                sx={{
                  fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '19.2px',
                  letterSpacing: '-3.5%',
                }}
              />
              {i18n.t(keys.contactUs)}
            </Button>
          </Stack>
        )}

        <React.Fragment>
          <Table
            sx={{
              backgroundColor: '#262626',
              borderCollapse: 'separate',
              borderSpacing: 0,
              borderRadius: '16px',
              overflow: 'hidden',
            }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell isFirstColumn></StyledTableCell>
                <StyledTableCell
                  sx={{ fontWeight: 500, fontSize: '16px', lineHeight: '21px' }}
                >
                  {i18n.t(keys.basicSubscription)}
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    backgroundColor: '#066B16',
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '21px',
                    paddingLeft: '24px',
                  }}
                >
                  {i18n.t(keys.fieldSenseSubscription)}
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <FeatureRow
                featureName={i18n.t(keys.historicalDataAccessRow)}
                basicInfo={i18n.t(keys.oneYear)}
                fieldSenseInfo={i18n.t(keys.unlimited)}
                basicIcon={true}
                fieldSenseIcon={true}
              />
              <FeatureRow
                featureName={i18n.t(keys.notesTextRow)}
                basicInfo={'50'}
                fieldSenseInfo={i18n.t(keys.unlimited)}
                basicIcon={true}
                fieldSenseIcon={true}
              />
              <FeatureRow
                featureName={i18n.t(keys.notesProjectsRow)}
                basicInfo={i18n.t(keys.unlimited)}
                fieldSenseInfo={i18n.t(keys.unlimited)}
                basicIcon={true}
                fieldSenseIcon={true}
              />
              <FeatureRow
                featureName={i18n.t(keys.notesPhotosRow)}
                fieldSenseInfo={i18n.t(keys.unlimited)}
                basicIcon={false}
                fieldSenseIcon={true}
              />
              <FeatureRow
                featureName={i18n.t(keys.measurementToolboxRow)}
                basicIcon={true}
                fieldSenseIcon={true}
              />
              <FeatureRow
                featureName={i18n.t(keys.pdfReportRow)}
                basicIcon={true}
                fieldSenseIcon={true}
              />
              <FeatureRow
                featureName={i18n.t(keys.downloadDataRow)}
                basicIcon={true}
                fieldSenseIcon={true}
              />
              <FeatureRow
                featureName={i18n.t(keys.offlineMapsRow)}
                basicIcon={false}
                fieldSenseIcon={true}
              />
              <FeatureRow
                featureName={i18n.t(keys.smartSamplingCreatorRow)}
                basicIcon={false}
                fieldSenseIcon={true}
              />
              <FeatureRow
                featureName={i18n.t(keys.smartSamplingCollectorRow)}
                basicIcon={false}
                fieldSenseIcon={true}
                isLastRow={true}
              />
            </TableBody>
          </Table>
        </React.Fragment>
      </DialogContent>
    </Dialog>
  )
}

const StyledTableCell = ({
  isLastRow,
  isFirstColumn,
  ...props
}: React.ComponentProps<typeof TableCell> & {
  isLastRow?: boolean
  isFirstColumn?: boolean
}) => (
  <TableCell
    {...props}
    style={{
      padding: '16px',

      borderBottom: isLastRow ? 'none' : '1px solid rgba(224, 224, 224, 0.5)',
      width: isFirstColumn ? '46%' : '27%',
      paddingLeft: isFirstColumn ? '' : '24px',
      ...props.style,
    }}
  />
)

const FeatureRow = ({
  featureName,
  basicInfo,
  fieldSenseInfo,
  basicIcon,
  fieldSenseIcon,
  isLastRow,
}: {
  featureName: string
  basicInfo?: string
  fieldSenseInfo?: string
  basicIcon?: boolean
  fieldSenseIcon?: boolean
  isLastRow?: boolean
}) => (
  <TableRow>
    <StyledTableCell isLastRow={isLastRow} isFirstColumn>
      <TableBodyText>{featureName}</TableBodyText>
    </StyledTableCell>
    <StyledTableCell align="left" isLastRow={isLastRow}>
      <Stack direction="row" alignItems="center" spacing={1}>
        {basicIcon && (
          <CheckCircleIcon
            style={{
              color: '#8C8A88',
              flexShrink: 0,
            }}
          />
        )}
        <TableBodyText>{basicInfo}</TableBodyText>
      </Stack>
    </StyledTableCell>
    <StyledTableCell
      align="left"
      isLastRow={isLastRow}
      sx={{ backgroundColor: '#5CC46C33' }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        {fieldSenseIcon && (
          <CheckCircleIcon
            style={{
              flexShrink: 0,
              color: '#1EAC34',
            }}
          />
        )}
        <TableBodyText>{fieldSenseInfo}</TableBodyText>
      </Stack>
    </StyledTableCell>
  </TableRow>
)

export default SubscriptionDialog
