import { Project } from '../graphql/types'
import { NoteWithTemplate, OrgFeatureGeometry } from '../vvapi/models'
import { PIN_POINT_BUTTON_PRESS_TEMPLATE_ID } from './NoteForm'

export default function notesToGeoJSON(
  notes: (NoteWithTemplate | undefined)[],
  projectsById: Record<string, Project>,
  selectedNoteId?: string,
  focusedNoteId?: string
) {
  const colors: Set<string> = new Set()
  const noteGeojson = notes.map((note) => {
    let priority = 0
    let pinColor = note!.pinColor
    const isPinPoint = note!.templateId === PIN_POINT_BUTTON_PRESS_TEMPLATE_ID
    const noteLabel = note!.content?.note?.label
    const currProject = projectsById[note!.projectId || 'general']
    const pinPointColorOverride =
      currProject?.pinPointColorOverride?.[noteLabel]

    if (note!.id === selectedNoteId) {
      priority = 1
    }

    if (note!.id === focusedNoteId) {
      priority = 1
    }

    if (isPinPoint && pinPointColorOverride) {
      pinColor = pinPointColorOverride
    }

    colors.add(pinColor)

    return {
      id: note!.id,
      type: 'Feature',
      geometry: note!.feature.geometry,
      properties: {
        priority,
        color: pinColor,
        id: note!.id,
        meta: note!.content,
      },
    } as GeoJSON.Feature<OrgFeatureGeometry>
  })

  return [
    {
      features: noteGeojson,
      type: 'FeatureCollection',
    } as GeoJSON.FeatureCollection<OrgFeatureGeometry>,
    Array.from(colors),
  ] as const
}
