import { Icon, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import { Theme } from '@mui/material/styles'
import { createStyles, withStyles, WithStyles } from '@mui/styles'
import * as React from 'react'
import { PureComponent } from 'react'
import { selectFilteredParcels } from '../../data/selectFilteredParcels'
import { selectIsAdmin } from '../../data/selectMe'
import {
  GroupData,
  ParcelData,
  selectGroupDatesByGroupId,
} from '../../data/selectOrgMapData'
import { selectSelectedParcelIds } from '../../data/selectSelectedParcelIds'
import * as userSelection from '../../data/userSelectionRedux'
import i18n, { keys } from '../../i18n'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import { toggleSubscriptionDialog } from '../../subscriptions/actions'
import { SubscriptionLock } from '../../subscriptions/SubscriptionLock'
import { dateOlderThanLimit } from '../../subscriptions/util/limit'
import { ExpandButton } from '../../UI/Expand/ExpandButton'
import { SelectBox, Selection } from '../../UI/SelectBox/SelectBox'
import didPropChange from '../../util/didPropChange'
import { isNumericIdEqual } from '../../util/isNumericIdEqual'
import { toggleArrayValue } from '../../util/toggleArrayValue'
import * as actions from '../actions'
import { dateRangeString } from './getParcelDateString'
import ParcelListItem from './ParcelListItem'

interface Props {
  group: GroupData
  handleCheckboxClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  handleCollapseClick?: (event: React.MouseEvent<HTMLInputElement>) => void
  locked?: boolean
  flightDateLimitInYears?: number
}

interface State {
  groupParcels: ParcelData[]
  groupSelection: Selection
}

class GroupListItem extends PureComponent<
  ReduxProps & Props & AppDispatchProps & WithStyles<typeof styles>,
  State
> {
  state: State = {
    groupParcels: [],
    groupSelection: 'unselected',
  }

  componentDidMount() {
    this.onUpdate()
  }
  componentDidUpdate(prevProps: this['props']) {
    this.onUpdate(prevProps)
  }

  onUpdate = (prevProps?: this['props']) => {
    if (
      didPropChange('selectedParcelIds', prevProps, this.props) ||
      didPropChange('filteredParcels', prevProps, this.props)
    ) {
      const { selectedParcelIds, filteredParcels } = this.props

      const groupParcels = filteredParcels.filter(({ groupId }) =>
        isNumericIdEqual(groupId, this.props.group.id)
      )

      this.setState({
        groupParcels,
        groupSelection: getGroupSelection(groupParcels, selectedParcelIds),
      })
    }
  }

  onClickLock = (event: React.MouseEvent<HTMLInputElement>) => {
    const { dispatch } = this.props

    event.preventDefault()
    event.stopPropagation()

    dispatch(toggleSubscriptionDialog(true))
  }

  render() {
    const {
      group,
      classes,
      expandedGroupIds,
      locked,
      // isAdmin
      flightDateLimitInYears = Infinity,
    } = this.props
    const { groupParcels, groupSelection } = this.state

    const isExpanded = expandedGroupIds.includes(group.id)
    const dates = dateRangeString(group.flightDates.slice(-1))
    const disabled = !dates || locked

    const datesString = dates || i18n.t(keys.generic.unavailable)

    // wrap each section in a div to push previous stickies out of the way.
    return (
      <div>
        <div className={classes.stickyDiv}>
          <div className={classes.listItemContainer}>
            <ListItem
              button
              disableGutters
              dense
              className={classes.ListItem}
              onClick={this.handleCollapseClick}
              disableRipple
              sx={{
                backgroundColor: disabled
                  ? 'rgba(66, 66, 66, 0.38)'
                  : 'inherit',
              }}
            >
              <ExpandButton
                disabled={disabled}
                style={{ padding: 0 }}
                expanded={isExpanded}
                className="GroupListItem__isCollapsed"
              />
              <SelectBox
                disabled={disabled}
                style={{ padding: 0 }}
                selection={groupSelection}
                indeterminateIcon={<Icon>add_box</Icon>}
                color="primary"
                onClick={this.handleCheckboxClick}
                disableRipple
                className="GroupListItem__isSelected"
              />

              <ListItemText
                style={{ marginLeft: 20 }}
                primary={
                  <Typography
                    variant="body2"
                    style={{ textTransform: 'uppercase' }}
                  >
                    {group.name}
                  </Typography>
                }
                secondary={
                  <Typography
                    variant="caption"
                    style={{ textTransform: 'uppercase' }}
                  >
                    {datesString}
                  </Typography>
                }
                disableTypography
              />

              {/* <ListItemSecondaryAction>
              <GroupListItemMenu
                group={group}
                deliveryGroupFiles={group.deliveryGroupFiles}
              />
            </ListItemSecondaryAction> */}
              {/* {isAdmin && group.hasInactiveLayers && (
              <ListItemIcon>
                <Tooltip
                  popupAnchor="middle-left"
                  originAnchor="middle-right"
                  title="Has inactive layers"
                  style={{
                    margin: 4,
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    fontSize: 14,
                  }}
                >
                  <Icon fontSize="inherit">visibility_off</Icon>
                </Tooltip>
              </ListItemIcon>
            )} */}
            </ListItem>
            {locked && (
              <Stack
                className={classes.disabledOverlay}
                alignItems="end"
                justifyContent="center"
                onClick={this.onClickLock}
              >
                <SubscriptionLock locked={locked} />
              </Stack>
            )}
          </div>
        </div>
        <Collapse
          classes={{
            wrapperInner: classes.parcelContainer,
          }}
          in={isExpanded}
        >
          {groupParcels.map((parcel) => (
            <ParcelListItem
              key={parcel.id}
              parcel={parcel}
              flightDateLimitInYears={flightDateLimitInYears}
            />
          ))}
        </Collapse>
      </div>
    )
  }

  handleCheckboxClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const {
      dispatch,
      group,
      selectedFlightDate,
      flightDatesByGroupId,
      flightDateLimitInYears = Infinity,
    } = this.props
    const { groupSelection, groupParcels } = this.state

    const flightDates = flightDatesByGroupId[group.id] || []
    const availableFlightDates = flightDates
      .map((fd) => fd.flightDate)
      .filter((date) => !dateOlderThanLimit(date, flightDateLimitInYears))

    // the checkbox is within a button; stop propagation to that button
    event.stopPropagation()

    const groupParcelIds = groupParcels.map(({ id: parcelId }) => parcelId)

    const newFlightDate = selectedFlightDate
      ? selectedFlightDate
      : availableFlightDates[0]

    // unselected or indeterminate selects all
    if (groupSelection === 'selected') {
      dispatch(
        userSelection.update({
          selectedGroupId: undefined,
          selectedParcelIds: [],
        })
      )
    } else {
      dispatch(
        userSelection.update({
          selectedGroupId: group.id,
          selectedParcelIds: groupParcelIds,
          selectedFlightDate: newFlightDate,
        })
      )
    }

    dispatch(actions.setFitSelectedParcels(true))
  }

  handleCollapseClick = (event: React.MouseEvent<HTMLInputElement>) => {
    const { group, dispatch, expandedGroupIds, locked } = this.props

    event.preventDefault()
    event.stopPropagation()

    if (locked) {
      dispatch(toggleSubscriptionDialog(true))
      return
    }

    if (!group) {
      return
    }

    dispatch(
      userSelection.update({
        expandedGroupIds: toggleArrayValue(expandedGroupIds, group.id),
      })
    )
  }
}

const styles = (theme: Theme) =>
  createStyles({
    ListItem: {
      backgroundColor: theme.palette.background.default,
    },
    listItemContainer: {
      display: 'relative',
    },
    disabledOverlay: {
      position: 'absolute',
      padding: '8px',
      backgroundColor: 'rgba(66, 66, 66, 0.38)',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
    },
    stickyDiv: {
      // we need to use the same backgroundColor as the <ParcelDrawer>
      // so that when you hover the <ListItem button> you don't see the
      // elements behind it and so it has the same hover backgroundColor
      // as the <ParcelListItem>
      backgroundColor: theme.palette.background.paper,
      position: 'sticky',
      zIndex: 1,
      top: 0,
    },
    parcelContainer: {
      display: 'flex',
      flexFlow: 'column nowrap',
    },
  })

function getGroupSelection(
  groupParcels: ParcelData[],
  selectedParcelIds: Set<ParcelData['id']>
): Selection {
  const selectedParcels = groupParcels.filter(({ id: parcelId }) =>
    selectedParcelIds.has(parcelId)
  )

  return selectedParcels.length === 0
    ? 'unselected'
    : selectedParcels.length === groupParcels.length
    ? 'selected'
    : 'partially-selected'
}

const mapState = (state: RootStore) => ({
  isAdmin: selectIsAdmin(state),
  filteredParcels: selectFilteredParcels(state),
  selectedParcelIds: selectSelectedParcelIds(state),
  expandedGroupIds: state.userSelection.expandedGroupIds ?? [],
  selectedFlightDate: userSelection.getSelectedFlightDate(state),
  flightDatesByGroupId: selectGroupDatesByGroupId(state),
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, Props, AppDispatchProps>(mapState)(
  withStyles(styles)(GroupListItem)
)
