import { createAsyncSelector } from '../../../AsyncSelector/createAsyncSelector'
import * as api from '../../../graphql/api'
import { Legacy_Subscription } from '../../../graphql/types'
import { RootStore } from '../../../redux/types'

export interface GetOrderData {
  id: string
  comment: string
  priority: number
  migratedSubscriptionId: number
  subscription: Pick<
    Legacy_Subscription,
    'id' | 'quoteId' | 'invoiceId' | 'purchaseOrderId'
  >
  quoteId: string
  invoiceId: string
  purchaseOrderId: string
  createdAt: string
  updatedAt: string
  orderConfirmationSentAt: string

  CreatedBy: {
    firstName: string
  }
  UpdatedBy: {
    firstName: string
  }
}

const get = ({ id }: { id: string }) =>
  api.order.get<GetOrderData>({
    pk: { id },
    returning: `{
      id
      orderConfirmationSentAt
      migratedSubscriptionId
      subscription: Legacy_Subscription {
        id
        id
        quoteId
        invoiceId
        purchaseOrderId
      }
      comment
      createdAt
      updatedAt
      priority
      quoteId
      invoiceId
      purchaseOrderId
      CreatedBy {
        firstName
      }
      UpdatedBy {
        firstName
      }
    }`,
  })

const { selector: selectGetOrder, refresh: refreshGetOrder } =
  createAsyncSelector({
    resource: 'GetOrderData',
    inputs: {
      orderId: (state: RootStore) => state.router.params.orderId,
    },
    fetcher: async ({ orderId }) => {
      if (!orderId) {
        return
      }

      const { data } = await get({ id: orderId })

      if (!data) {
        throw new Error('No Data')
      }

      return data
    },
  })

export { selectGetOrder, refreshGetOrder }
