import * as React from 'react'
import { ConfirmationModal } from '../../app/ConfirmationModal/ConfirmationModal'
import i18n, { keys } from '../../i18n'
import { DeleteProjectNotesForm } from './DeleteProjectNotesForm'

interface Props {
  open: boolean
  onCancel: () => void
  onProjectNotesDelete: () => void
}

export const DeleteProjectNotesModal = ({
  open,
  onCancel,
  onProjectNotesDelete,
}: Props) => {
  return (
    <ConfirmationModal
      open={open}
      title={i18n.t(keys.deleteSelectedNotesPromptLabel)}
      content={<DeleteProjectNotesForm />}
      onConfirm={onProjectNotesDelete}
      onCancel={onCancel}
      dangerMode
    />
  )
}
