import { AnyAction } from 'redux'
import { getOrganizationSubscriptionsAndFeatures } from './queries'
import {
  Subscription,
  SubscriptionFeature,
  Translation,
} from '../graphql/types'

export const GET_SUBSCRIPTION_FEATURES_REQUEST =
  'GET_SUBSCRIPTION_FEATURES_REQUEST'
export const GET_SUBSCRIPTION_FEATURES_SUCCESS =
  'GET_SUBSCRIPTION_FEATURES_SUCCESS'
export const GET_SUBSCRIPTION_FEATURES_FAILURE =
  'GET_SUBSCRIPTION_FEATURES_FAILURE'
export const TOGGLE_SUBSCRIPTION_DIALOG = 'TOGGLE_SUBSCRIPTION_DIALOG'

export const toggleSubscriptionDialog = (isOpen: boolean) => ({
  type: TOGGLE_SUBSCRIPTION_DIALOG,
  isOpen,
})

export const getSubscriptionFeaturesRequest = (): AnyAction => ({
  type: GET_SUBSCRIPTION_FEATURES_REQUEST,
})

export const getSubscriptionFeaturesSuccess = (
  features: Record<string, SubscriptionFeature>,
  subscriptionTypeName: Translation | undefined,
  subscriptionTypeId: string,
  subscriptions: Subscription[]
): AnyAction => ({
  type: GET_SUBSCRIPTION_FEATURES_SUCCESS,
  payload: {
    features,
    subscriptionTypeName,
    subscriptionTypeId,
    subscriptions,
  },
})

export const getSubscriptionFeaturesFailure = (error: string): AnyAction => ({
  type: GET_SUBSCRIPTION_FEATURES_FAILURE,
  payload: error,
})

export const fetchSubscriptionFeatures = async (
  orgID: string,
  isAdmin: boolean,
  dispatch: (action: AnyAction) => void
) => {
  dispatch(getSubscriptionFeaturesRequest())
  try {
    const { prioritizedSubscriptionFeatures, subscriptions } =
      await getOrganizationSubscriptionsAndFeatures({
        organizationId: orgID,
        isAdmin,
      })
    const fieldsenseSubscription = subscriptions.find(
      (sub) => sub.SubscriptionType?.id === 'fieldsense'
    )
    const pinPointSubscription = subscriptions.find(
      (sub) => sub.SubscriptionType?.id === 'pinpoint'
    )

    const subscriptionTypeName = fieldsenseSubscription
      ? fieldsenseSubscription.SubscriptionType?.name
      : {
          en: 'Basic',
          fr: 'Basique',
        }

    const subscriptionTypeId = fieldsenseSubscription?.SubscriptionType?.id
      ? fieldsenseSubscription.SubscriptionType.id
      : pinPointSubscription?.SubscriptionType?.id
      ? pinPointSubscription.SubscriptionType.id
      : 'basic'

    dispatch(
      getSubscriptionFeaturesSuccess(
        prioritizedSubscriptionFeatures,
        subscriptionTypeName,
        subscriptionTypeId,
        subscriptions
      )
    )
  } catch (error) {
    dispatch(getSubscriptionFeaturesFailure(error.message))
  }
}
