import { createSelector } from 'reselect'

import { createAsyncSelector } from '../AsyncSelector/createAsyncSelector'
import * as api from '../graphql/api'
import { Legacy_Subscription } from '../graphql/types'
import { selectOrganizationId } from './selectOrganizationId'

const {
  selector: selectGetOrganizationSubscriptions,
  refresh: refreshOrganizationSubscriptions,
} = createAsyncSelector({
  resource: 'OrganizationSubscriptions',
  inputs: {
    orgId: selectOrganizationId,
  },
  fetcher: async ({ orgId }) => {
    if (!orgId) {
      return []
    }

    const { data = [] } = (await api.subscription.list({
      where: { organizationId: { _eq: orgId }, _not: { Orders: {} } } as any,
      order_by: '{createdAt: desc}',
      returning: `{
        id
        quoteId
        invoiceId
        purchaseOrderId
      }`,
    })) as { data: Legacy_Subscription[] }

    if (!data) {
      throw new Error('No Data')
    }

    return data
  },
})

export { selectGetOrganizationSubscriptions, refreshOrganizationSubscriptions }

export const selectOrganizationSubscriptions = createSelector(
  selectGetOrganizationSubscriptions,
  (getOrganizationSubscriptions) => {
    return getOrganizationSubscriptions.data ?? []
  }
)
