import * as React from 'react'
import {
  Button,
  Icon,
  Menu,
  MenuItem,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material'
import i18n, { keys } from '../i18n'
import { Tool } from './types'
import { KeyboardArrowDown, KeyboardArrowUp, Lock } from '@mui/icons-material'
import { useFeatureFlag } from '../hooks/useFeatureFlag'
import { useRedux } from '../hooks/useRedux'
import { toggleSubscriptionDialog } from '../subscriptions/actions'
import useSubscriptionFeature from '../subscriptions/hooks/useSubscriptionFeature'

interface Props {
  tools?: Tool[]
  color?: string
}

const ToolMenu = styled(Menu)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  '& .MuiMenu-list': {
    padding: 0,
  },
  '& .MuiPaper-root': {
    marginTop: '4px',
  },
}))

const ToolText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: 1.43,
  letterSpacing: '-0.035em',
  textAlign: 'left',
}))

const ToolIcon = styled(Icon)(({ theme }) => ({
  width: '24px',
  height: '24px',
}))

interface ToolMenuItemProps {
  tool: Tool
  isLocked: boolean
  onClickOption: (tool: Tool) => void
}

const ToolMenuItem = ({ tool, isLocked, onClickOption }: ToolMenuItemProps) => (
  <Tooltip
    title={
      isLocked ? i18n.t(keys.upgradeSubscriptionTooltip) : tool.description
    }
  >
    <MenuItem
      key={tool.id}
      onClick={() => onClickOption(tool)}
      sx={{
        padding: '10px 16px',
        width: '100%',
        '&:hover': {
          backgroundColor: (theme) => theme.palette.action.hover,
        },
        '&:last-of-type': {
          paddingBottom: '12px',
        },
        '&:first-of-type': {
          paddingTop: '12px',
        },
        backgroundColor: isLocked ? '#00000033' : '',
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ width: '100%' }}
      >
        <ToolIcon sx={{ opacity: isLocked ? 0.5 : 1 }}>{tool.icon}</ToolIcon>
        <ToolText sx={{ opacity: isLocked ? 0.5 : 1, flexGrow: 1 }}>
          {tool.name}
        </ToolText>
        {isLocked && <Lock fontSize="medium" sx={{ opacity: 1 }} />}
      </Stack>
    </MenuItem>
  </Tooltip>
)

export const ToolSelector = ({ tools, color }: Props) => {
  const [, dispatch] = useRedux()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const { enabled: smartSamplingSubscriptionFeatureEnabled } =
    useSubscriptionFeature({ featureType: 'smart-sampling' })
  const { enabled: rateMapSubscriptionFeatureEnabled } = useSubscriptionFeature(
    { featureType: 'rate-maps' }
  )

  const openContext = Boolean(anchorEl)

  const { featureEnabled: rateMapEnabled } = useFeatureFlag({
    featureFlagId: 'rate-maps',
  })
  const { featureEnabled: samplePlanningEnabled } = useFeatureFlag({
    featureFlagId: 'sample-planning',
  })

  const filteredTools = React.useMemo(() => {
    return (
      tools?.filter((tool) => {
        return (
          (tool.id === 'rate-maps' &&
            rateMapEnabled &&
            rateMapSubscriptionFeatureEnabled) ||
          (tool.id === 'sample-planning' &&
            samplePlanningEnabled &&
            smartSamplingSubscriptionFeatureEnabled)
        )
      }) ?? []
    )
  }, [tools, rateMapEnabled, samplePlanningEnabled])

  const disabledTools = React.useMemo(() => {
    return (
      tools?.filter((tool) => {
        return (
          (tool.id === 'rate-maps' && !rateMapSubscriptionFeatureEnabled) ||
          (tool.id === 'sample-planning' &&
            !smartSamplingSubscriptionFeatureEnabled)
        )
      }) ?? []
    )
  }, [
    rateMapSubscriptionFeatureEnabled,
    smartSamplingSubscriptionFeatureEnabled,
    tools,
  ])

  const handleOpenContext = (
    event: React.MouseEvent<HTMLElement>,
    ref: HTMLElement | null
  ) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(ref)
  }

  const handleCloseContext = () => {
    setAnchorEl(null)
  }

  if (
    (!filteredTools || filteredTools.length === 0) &&
    (!disabledTools || disabledTools.length === 0)
  ) {
    return null
  }

  const onClickOption = (tool: Tool) => {
    tool.onClick()
    handleCloseContext()
  }

  const openSubscriptionDialog = () => {
    dispatch(toggleSubscriptionDialog(true))
  }

  return (
    <>
      <Button
        style={{
          color,
          backgroundColor: 'var(--green-hover)',
          padding: '4px 16px',
        }}
        onClick={(event) => handleOpenContext(event, event.currentTarget)}
        endIcon={openContext ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      >
        {i18n.t(keys.tools)}
      </Button>
      <ToolMenu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleCloseContext}
        open={openContext}
      >
        {filteredTools.map((tool) => (
          <ToolMenuItem
            tool={tool}
            isLocked={false}
            onClickOption={onClickOption}
          />
        ))}
        {disabledTools.map((tool) => (
          <ToolMenuItem
            tool={tool}
            isLocked={true}
            onClickOption={openSubscriptionDialog}
          />
        ))}
      </ToolMenu>
    </>
  )
}
