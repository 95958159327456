import styled from '@emotion/styled'
import { Close } from '@mui/icons-material'
import { IconButton, Stack, Typography } from '@mui/material'
import * as React from 'react'
import { ConfirmationModal } from '../../app/ConfirmationModal/ConfirmationModal'
import { Project } from '../../graphql/types'
import { useRedux } from '../../hooks/useRedux'
import i18n, { keys } from '../../i18n'
import { refreshListNotes, refreshListProjects } from '../../notes/selectors'
import { showFeedback } from '../../redux/notifications/redux'
import { NoteWithTemplate } from '../../vvapi/models'
import { moveNotes } from '../queries'
import { MoveNotesForm } from './MoveNotesForm'

interface Props {
  open: boolean
  onCancel: () => void
  onMoveNotes?: (updatedProject?: Project) => void
  selectedNotes?: Record<string, NoteWithTemplate>
}

const Title = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.15px;
  text-align: left;
`

export const MoveNotesModal = ({
  open,
  onCancel,
  onMoveNotes,
  selectedNotes,
}: Props) => {
  const [_, dispatch] = useRedux()
  const [newProjectId, setNewProjectId] = React.useState<string>()

  const moveNotesToProject = async () => {
    const result = await moveNotes(
      Object.keys(selectedNotes ?? {}),
      newProjectId
    )

    if (result?.data) {
      refreshListNotes()
      refreshListProjects()
      onMoveNotes?.()
      dispatch(
        showFeedback({
          message: i18n.t(keys.moveAllProjectNotesSuccessMessage),
          severity: 'success',
        })
      )
    } else {
      dispatch(
        showFeedback({
          message: i18n.t(keys.moveAllProjectNotesFailedMessage),
          severity: 'error',
        })
      )
    }
  }

  React.useEffect(() => {
    if (!open) {
      setNewProjectId(undefined)
    }
  }, [open])

  return (
    <ConfirmationModal
      open={open}
      onCancel={onCancel}
      onConfirm={moveNotesToProject}
      title={
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Title>{i18n.t(keys.moveNotesModalTitle)}</Title>
          <IconButton onClick={onCancel}>
            <Close />
          </IconButton>
        </Stack>
      }
      content={
        <MoveNotesForm
          onNewProjectIdChange={(projectId) => setNewProjectId(projectId)}
          newProjectId={newProjectId}
        />
      }
    />
  )
}
