import { FormControl, Typography } from '@mui/material'
import React from 'react'
import DatePickerYMD from '../../../admin/mapdata/utils/DatePickerYMD'

interface DateSelectProps {
  title: string
  selectedDate: Date | null
  setSelectedDate: (value: Date) => void
  allowEmpty?: boolean
}

export const SubscriptionDateSelect = ({
  title,
  selectedDate,
  setSelectedDate,
  allowEmpty = false,
}: DateSelectProps) => {
  return (
    <FormControl>
      <Typography>{title}</Typography>
      <DatePickerYMD
        name={title}
        value={selectedDate}
        onChange={({ value }) => setSelectedDate(value)}
        required={allowEmpty}
        begin={'start-of-day'}
      />
    </FormControl>
  )
}
