import { AnyAction, Dispatch } from 'redux'
import applyNamespace from 'redux-ts-helpers/lib/applyNamespace'

import * as Sentry from '@sentry/browser'

import { waitForResource } from '../AsyncSelector/waitForResource'
import { selectOrganization } from '../data/selectOrganization'
import i18n, { keys } from '../i18n'
import { setCanUpdate } from '../redux/persistenceMiddleware'
import { getState } from '../redux/store'
import { ReduxAsyncAction } from '../redux/types'
import handleError from '../util/handleError'
import { setCurrentOrg } from './apiResource/createApiResource'
import fetchAndMergeState from './fetchAndMergeState'
import { default as vvapi } from './index'
import { LoginResponse } from './models'
import { deletePersistedToken, persistToken, persistedToken } from './tokenUtil'
import { LoginState } from './types'
import { isValidEmail } from '../util/isEmailValid'
import { fetchSubscriptionFeatures } from '../subscriptions/actions'
import { selectMe } from '../data/selectMe'

const loginInitialState: LoginState = {
  isInProgress: false,
  isPostLoginInProgress: false,
  isLoggedIn: false,
}

const fetchAndPersistState = async (dispatch: Dispatch): Promise<void> => {
  try {
    let state = getState()

    if (state) {
      // tslint:disable-next-line: no-parameter-reassignment
      state = await fetchAndMergeState(state, dispatch)

      if (state?.preferences?.preferredLanguage) {
        i18n.changeLanguage(state.preferences.preferredLanguage)
      }

      // now that state has been fetched, we should enable state to be persisted
      setCanUpdate(true)

      if (state.login.isInProgress || state.login.isLoggedIn) {
        // wait for the organization list to populate before moving to the next step
        await waitForResource('me.organizations')
        // wait for the roles to populate before moving to the next step
        await waitForResource('me')

        state = getState() ?? state

        const organization = state && selectOrganization(state)

        const isAdmin = state && selectMe(state)?.roles?.includes('admin')

        if (organization) {
          Sentry.configureScope((scope) => {
            scope.setTag('organization', organization.name)
          })

          setCurrentOrg(organization.id)
          fetchSubscriptionFeatures(organization.id, !!isAdmin, dispatch)
        }
      }
    }
  } catch (e) {
    handleError(e)
  }
}

export const login = (() => {
  const constants = applyNamespace('login', {
    login: 0,
    tryPersistedKey: 0,
    register: 0,
    logout: 0,
    postLogin: 0,
    resetPassword: 0,
  })

  const actions = {
    login: (email: string, password: string): ReduxAsyncAction => ({
      type: constants.login,
      // tslint:disable-next-line: no-shadowed-variable
      async payload() {
        if (!email) {
          throw keys.forms.login.emailRequired
        }
        if (!isValidEmail(email)) {
          throw keys.emailInvalid
        }
        if (!password) {
          throw keys.forms.login.passwordRequired
        }

        try {
          const res = await vvapi.auth.login(email, password)
          persistToken(res.token)

          return res
        } catch (e) {
          if (e?.response?.status === 403) {
            throw keys.forms.login.emailOrPasswordIncorrect
          }

          throw keys.forms.login.loginError
        }
      },
    }),
    register: ({
      firstName,
      lastName,
      email,
      password,
      token,
    }: {
      firstName: string
      lastName: string
      email: string
      password: string
      token: string
    }): ReduxAsyncAction => ({
      type: constants.register,
      async payload() {
        const res = await vvapi.auth.register(
          firstName,
          lastName,
          email,
          password,
          token
        )

        persistToken(res.token)

        return res
      },
    }),

    resetPassword: ({
      token,
      password,
    }: {
      token: string
      password: string
    }) => ({
      type: constants.resetPassword,
      payload: vvapi.auth.resetPassword(token, password),
    }),

    tryPersistedKey: (): ReduxAsyncAction => ({
      type: constants.tryPersistedKey,
      // tslint:disable-next-line: no-shadowed-variable
      async payload() {
        const token = persistedToken()
        if (!token) {
          // if you don't return anything, the reducers don't trigger!
          return {}
        }

        try {
          const res = await vvapi.auth.checkAuth()

          return res
        } catch (e) {
          return {}
        }
      },
    }),

    logout: () => ({
      type: constants.logout,
      payload() {
        deletePersistedToken()
      },
    }),

    postLogin: (): ReduxAsyncAction => ({
      type: constants.postLogin,
      async payload(dispatch) {
        await fetchAndPersistState(dispatch)
      },
    }),
  }

  function reducer(state = loginInitialState, action: AnyAction): LoginState {
    switch (action.type) {
      case `${constants.login}/start`:
      case `${constants.tryPersistedKey}/start`:
        return {
          ...state,
          error: undefined,
          isInProgress: true,
        }
      case `${constants.postLogin}/start`: {
        return {
          ...state,
          isPostLoginInProgress: true,
        }
      }
      case `${constants.postLogin}/success`:
      case `${constants.postLogin}/error`: {
        return {
          ...state,
          error: action.payload,
          isPostLoginInProgress: false,
        }
      }
      case `${constants.login}/success`:
      case `${constants.tryPersistedKey}/success`:
      case `${constants.register}/success`:
        if (!action.payload) {
          return state
        }

        const { user } = action.payload as LoginResponse

        return {
          ...state,
          error: undefined,
          isLoggedIn: !!user,
          isInProgress: false,
        }

      case `${constants.login}/error`:
      case `${constants.tryPersistedKey}/error`:
        return {
          ...state,
          error: action.payload,
          isLoggedIn: false,
          isInProgress: false,
        }

      case `${constants.logout}/success`:
        return {
          ...state,
          error: undefined,
          isLoggedIn: false,
          isInProgress: false,
        }

      default:
        return state
    }
  }

  return { actions, reducer, constants }
})()
