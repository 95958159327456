import * as Sentry from '@sentry/browser'
import { PureComponent } from 'react'
import {
  selectSelectedDeliveryParcels,
  selectSelectedDeliveryParcelsWithoutFlightDate,
} from '../data/selectSelectedDeliveryParcels'
import withMap, { WithMap } from '../map/withMap'
import { connect } from '../redux/connect'
import { AppDispatchProps, RootStore } from '../redux/types'
import handleError from '../util/handleError'
import { setFitSelectedParcels } from './actions'
import getBoundsFromParcels from './getBoundsFromParcels'

class FitSelectedParcels extends PureComponent<
  WithMap & ReduxProps & AppDispatchProps
> {
  componentDidUpdate(prevProps: this['props']) {
    if (this.props.fitSelectedParcels) {
      this.fitSelectedParcels()
    }
  }

  fitSelectedParcels() {
    const {
      map,
      availableSelectedParcels,
      unavailableSelectedParcels,
      dispatch,
      showSidebarLeft,
      showSidebarRight,
    } = this.props

    let parcels =
      availableSelectedParcels.length > 0
        ? availableSelectedParcels
        : unavailableSelectedParcels

    if (parcels.length === 0) {
      return
    }

    // compute combined bounding box for selected parcels
    const bounds = getBoundsFromParcels(parcels)

    try {
      map.fitBounds(bounds as any, {
        // if a sidebar is open add extra padding so they don't cover the data.
        padding: {
          top: 100,
          bottom: 100,
          left: showSidebarLeft ? 400 : 100,
          right: showSidebarRight ? 400 : 100,
        },
        duration: 0,
      })
      dispatch(setFitSelectedParcels(false))
    } catch (e) {
      Sentry.addBreadcrumb({
        category: 'map-ui',
        message: `FitSelectedParcels.fitBounds fitBounds=${JSON.stringify(
          bounds
        )}`,
        level: Sentry.Severity.Error,
      })
      handleError(e)
    }
  }

  render() {
    return null
  }
}

const mapState = (state: RootStore) => ({
  fitSelectedParcels: state.postgis.fitSelectedParcels,
  availableSelectedParcels: selectSelectedDeliveryParcels(state),
  unavailableSelectedParcels:
    selectSelectedDeliveryParcelsWithoutFlightDate(state),
  showSidebarLeft: state.preferences.showSidebarLeft,
  showSidebarRight: state.preferences.showSidebarRight,
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withMap(FitSelectedParcels)
)
