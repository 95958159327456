import { CardMembership, NavigateNext } from '@mui/icons-material'
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { selectPreferredLanguage } from '../data/selectPreferredLanguage'
import { useRedux } from '../hooks/useRedux'
import i18n, { keys } from '../i18n'
import WrappingMenuItem from '../UI/WrappingMenuItem'
import { toggleSubscriptionDialog } from './actions'

export const SubscriptionMenuOption = () => {
  const [state, dispatch] = useRedux()
  const preferredLanguage = selectPreferredLanguage(state)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const parentItemRef = React.useRef<HTMLLIElement>(null)
  const handleClick = () => {
    dispatch(toggleSubscriptionDialog(true))
  }
  const subscriptions = React.useMemo(
    () =>
      state?.subscription?.subscriptions.filter(
        (s) => s.SubscriptionType?.id !== 'basic'
      ),
    [state.subscription.subscriptions]
  )
  return (
    <>
      <WrappingMenuItem
        key="subscriptions"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        ref={parentItemRef}
      >
        <Stack direction={'row'} justifyContent="space-between" width="100%">
          <Stack direction={'row'}>
            <ListItemIcon>
              <CardMembership />
            </ListItemIcon>
            <ListItemText primary={i18n.t(keys.subscriptions)} />
          </Stack>
          <NavigateNext />
        </Stack>
      </WrappingMenuItem>
      <Menu
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            style: {
              borderRadius: '0px 0px 10px 10px',
            },
          },
        }}
      >
        {subscriptions?.length === 0 && (
          <MenuItem
            key="no-active-subscriptions"
            onClick={handleClick}
            style={{
              width: parentItemRef.current?.clientWidth,
            }}
          >
            <Typography color="grey">
              {i18n.t(keys.noActiveSubscriptions)}
            </Typography>
          </MenuItem>
        )}
        {subscriptions?.map((sub) => (
          <MenuItem
            key={sub.id}
            onClick={handleClick}
            style={{
              width: parentItemRef.current?.clientWidth,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <ListItemText
                primary={
                  (sub.SubscriptionType?.name as Record<string, string>)?.[
                    preferredLanguage
                  ]
                }
              />
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
