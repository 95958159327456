import './Page.scss'

import * as React from 'react'

import {
  AppBar,
  Button,
  Drawer,
  Icon,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import { useTranslation } from 'react-i18next'
import { FeatureToggle } from '../feature-flags/FeatureToggle'
import { useIsLive } from '../hooks/useIsLive'
import { useRedux } from '../hooks/useRedux'
import { keys } from '../i18n'
import { updatePreferences } from '../redux/preferences/redux'
import SubscriptionDialog from '../subscriptions/SubscriptionDialog'
import { DrawerIcon } from '../UI/icons/drawer-icon'
import LinkChild from '../UI/LinkChild'
import UserMenuButton from '../users/UserMenuButton'
import { classnames } from '../util/classnames'
import { ToolSelector } from './ToolSelector'
import { Tool } from './types'

export const APP_BAR_ELEMENT_ID = 'APP_BAR_ELEMENT_ID'

export interface PageProps {
  loading?: boolean
  backTo?: (() => string | undefined) | string
  backToTitle?: string
  leftSidebar?: React.ReactNode
  navChildren?: React.ReactNode
  rightSidebar?: React.ReactNode
  subtitle?: React.ReactNode
  title?: string
  alignTitle?: 'center' | 'left'
  tools?: Tool[]
}

// eslint-disable-next-line  import/no-anonymous-default-export
export default (props: React.PropsWithChildren<PageProps>) => {
  const { children, leftSidebar, rightSidebar, alignTitle = 'center' } = props

  const isLive = useIsLive()

  const theme = useTheme()
  const [state, dispatch] = useRedux()

  const leftSidebarOpen = state?.preferences?.showSidebarLeft
  const rightSidebarOpen = state?.preferences?.showSidebarRight

  const matchesSmall = useMediaQuery('(max-width:800px)')

  const [t] = useTranslation()

  React.useEffect(() => {
    setDocumentTitle(props.title)
  }, [props.title])

  const setDocumentTitle = (title?: string) => {
    const pageTitle = title ? `${title} | VineView` : 'VineView'

    document.title = pageTitle
  }

  const renderNav = () => {
    const {
      title,
      subtitle,
      backTo,
      backToTitle,
      leftSidebar,
      navChildren,
      rightSidebar,
      tools,
    } = props

    const backToString = typeof backTo === 'function' ? backTo() : backTo

    return (
      <AppBar
        id={APP_BAR_ELEMENT_ID}
        className="ui-nav main-nav"
        sx={{ px: 1 }}
      >
        {leftSidebar && (
          <Button
            onClick={toggleLeftSidebar}
            aria-label="Left Sidebar"
            style={{ fontSize: 24, minWidth: 0 }}
          >
            <DrawerIcon variant="left" />
          </Button>
        )}
        {backToString && (
          <LinkChild to={backToString}>
            <Button
              aria-label="Back"
              style={{ minWidth: 0, textTransform: 'none' }}
            >
              <Icon>keyboard_arrow_left</Icon> {backToTitle}
            </Button>
          </LinkChild>
        )}

        <div className="main-nav-title-container">
          <div className="main-nav-title">
            <Typography
              style={{ fontWeight: 500 }}
              variant="subtitle1"
              color="inherit"
            >
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="caption" color="inherit">
                {subtitle}
              </Typography>
            )}
          </div>
          <div className="nav-children-container">{navChildren}</div>
        </div>

        <FeatureToggle featureFlagId="tools-menu">
          <div className="main-nav-tools">
            <ToolSelector tools={tools} />
          </div>
        </FeatureToggle>

        {props.loading ? null : <UserMenuButton />}

        {rightSidebar && (
          <Button
            className="ui-right-sidebar-btn"
            onClick={toggleRightSidebar}
            aria-label="Right Sidebar"
            style={{ fontSize: 24, minWidth: 0 }}
          >
            <DrawerIcon variant="right" />
          </Button>
        )}
      </AppBar>
    )
  }

  const toggleLeftSidebar = () => {
    dispatch(updatePreferences({ showSidebarLeft: !leftSidebarOpen }))
  }

  const toggleRightSidebar = () => {
    dispatch(updatePreferences({ showSidebarRight: !rightSidebarOpen }))
  }

  return (
    <>
      {leftSidebar ? (
        <Drawer
          className="no-print"
          open={leftSidebarOpen ?? false}
          anchor="left"
          onClose={toggleLeftSidebar}
          hideBackdrop={!matchesSmall}
          variant={matchesSmall ? 'temporary' : 'persistent'}
        >
          {leftSidebar}
        </Drawer>
      ) : null}
      {rightSidebar ? (
        <Drawer
          className="no-print"
          open={rightSidebarOpen ?? false}
          anchor="right"
          onClose={toggleRightSidebar}
          hideBackdrop={!matchesSmall}
          variant={matchesSmall ? 'temporary' : 'persistent'}
        >
          {rightSidebar}
        </Drawer>
      ) : null}

      <div
        className={classnames(
          'ui-app-row',
          ['left-sidebar-open', !!(leftSidebar && leftSidebarOpen)],
          ['right-sidebar-open', !!(rightSidebar && rightSidebarOpen)],
          ['center-nav-title', alignTitle === 'center']
        )}
      >
        <div className="ui-content main-content" id="main-content">
          {renderNav()}
          {!isLive && (
            <div
              className="ui-nav beta-banner"
              id="beta-banner"
              style={{ zIndex: theme.zIndex.appBar }}
            >
              {/* Experimenting  with using the hook
              instead of the direct method,
              still doesn't work */}
              <Tooltip title={t(keys.betaMessage)!} placement="bottom">
                <div className="beta-label">{t(keys.beta)!}</div>
              </Tooltip>
            </div>
          )}
          <div className="ui-content">{children}</div>
        </div>
      </div>
      <SubscriptionDialog />
    </>
  )
}
