import { Add, Delete } from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  TableContainer,
  Tooltip,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from 'material-react-table'
import * as React from 'react'
import { selectPreferredLanguage } from '../../../data/selectPreferredLanguage'
import { Languages } from '../../../graphql/types'
import useAsync from '../../../hooks/useAsync'
import { useRedux } from '../../../hooks/useRedux'
import i18n from '../../../i18n'
import { showFeedback } from '../../../redux/notifications/redux'
import { useDefaultTableConfig } from '../../../UI/MaterialReactTable/useDefaultTableConfig'
import { AdminPage } from '../AdminPage'
import { AddSubscriptionModal } from './AddSubscriptionModal'
import { DeleteSubscriptionModal } from './DeleteSubscriptionModal'
import {
  createSubscription,
  deleteSubscription,
  getSubscriptions,
  getSubscriptionTypes,
} from './queries'

const PENDING_STATUS = 'pending'
const ACTIVE_STATUS = 'active'
const EXPIRED_STATUS = 'expired'

export interface SubscriptionRow {
  id: string
  organizationId: string
  organizationName: string
  billingCountry: string
  subscriptionTypeId: string
  subscriptionTypeName: string
  effectiveAt: string
  expiresAt: string
  status: string
}

export const Subscriptions = () => {
  const [state, dispatch] = useRedux()

  const [columnFilters, setColumnFilters] =
    React.useState<MRT_ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [sorting, setSorting] = React.useState<MRT_SortingState>([])
  const [pagination, setPagination] = React.useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 25,
  })

  const [showFullHistory, setShowFullHistory] = React.useState(false)
  const [selectedOrganization, setSelectedOrganization] = React.useState<
    | {
        value: string
        label: string
      }
    | undefined
  >({
    value: '',
    label: '',
  })
  const [addSubscriptionOpen, setAddSubscriptionOpen] = React.useState(false)

  const [deleteSubscriptionRow, setDeleteSubscriptionRow] =
    React.useState<SubscriptionRow>()

  const onCancelAddSubscription = () => {
    setAddSubscriptionOpen(false)
  }

  const language = selectPreferredLanguage(state)
  const [subscriptionTypesRequest] = useAsync(async () => {
    return await getSubscriptionTypes()
  }, [])

  const subscriptionTypesFilterOptions = React.useMemo(() => {
    return (
      subscriptionTypesRequest?.result?.map((st) => {
        return {
          value: st.id,
          label: (st.name as Record<Languages, string>)[language],
        }
      }) ?? []
    )
  }, [subscriptionTypesRequest.result, language])

  const [subscriptionRequest, refresh] = useAsync(async () => {
    return await getSubscriptions(
      globalFilter,
      columnFilters,
      pagination,
      showFullHistory
    )
  }, [globalFilter, columnFilters, pagination, showFullHistory])

  const subscriptionRowCount = React.useMemo(() => {
    return subscriptionRequest?.result?.count ?? 0
  }, [subscriptionRequest.result])

  const subscriptionData = React.useMemo(() => {
    return subscriptionRequest?.result?.subscriptions ?? []
  }, [subscriptionRequest.result])

  const subscriptionRows = React.useMemo<SubscriptionRow[]>(() => {
    return subscriptionData.reduce((acc, subscription) => {
      const organization = subscription.Organization
      acc.push({
        id: subscription.id ?? '',
        organizationId: organization.id ?? '',
        organizationName: organization.name ?? 'None',
        billingCountry: organization?.billingCountry ?? 'None',
        subscriptionTypeId: subscription.SubscriptionType?.id ?? '',
        subscriptionTypeName:
          (subscription.SubscriptionType?.name as Record<Languages, string>)?.[
            language
          ] ?? 'None',
        effectiveAt: subscription.effectiveAt,
        expiresAt: subscription.expiresAt,
        // if the current date is before the effective date, the subscription is pending
        // if the current date is after the expiry date, the subscription is expired
        // otherwise, the subscription is active
        status: dayjs().isBefore(subscription.effectiveAt)
          ? PENDING_STATUS
          : dayjs().isAfter(subscription.expiresAt)
          ? EXPIRED_STATUS
          : ACTIVE_STATUS,
      })
      return acc
    }, [] as SubscriptionRow[])
  }, [language, subscriptionData])

  const onAddSubscription = async (
    organizationId: string,
    subscriptionTypeId: string,
    effectiveAt: Date,
    expiresAt: Date | null
  ) => {
    try {
      await createSubscription(
        organizationId,
        subscriptionTypeId,
        effectiveAt,
        expiresAt
      )
      dispatch(
        showFeedback({
          message: 'Successfully created subscription',
          severity: 'success',
        })
      )
      setAddSubscriptionOpen(false)
      setSelectedOrganization(undefined)
      refresh()
    } catch (e) {
      console.error(e)
      dispatch(
        showFeedback({
          message: 'Error creating subscription',
          severity: 'error',
        })
      )
      setAddSubscriptionOpen(false)
    }
  }

  const onDeleteSubscription = async () => {
    try {
      await deleteSubscription(deleteSubscriptionRow?.id)
      dispatch(
        showFeedback({
          message: 'Successfully deleted subscription',
          severity: 'success',
        })
      )
      setDeleteSubscriptionRow(undefined)
      refresh()
    } catch (e) {
      console.error(e)
      dispatch(
        showFeedback({
          message: 'Error deleting subscription',
          severity: 'error',
        })
      )
      setDeleteSubscriptionRow(undefined)
    }
  }

  const onCancelDeleteSubscription = () => {
    setDeleteSubscriptionRow(undefined)
  }

  const rowCount = React.useMemo(() => {
    return subscriptionRows.length
  }, [subscriptionRows.length])

  const columns = React.useMemo<MRT_ColumnDef<SubscriptionRow>[]>(
    () => [
      {
        header: 'Organization Name',
        accessorKey: 'organizationName',
      },
      {
        header: 'Billing Country',
        accessorKey: 'billingCountry',
        filterVariant: 'select',
        size: 50,
      },
      {
        header: 'Subscription Type',
        accessorKey: 'subscriptionTypeName',
        aggregationFn: 'unique',
        enableGrouping: false,
        filterVariant: 'multi-select',
        filterSelectOptions: subscriptionTypesFilterOptions,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
        Cell: ({ cell }) => {
          const value = cell.getValue<string>()
          let bgColor = '#262626'
          if (value.toLowerCase().includes('fieldsense')) bgColor = '#5CC46C33'
          if (value.toLowerCase().includes('pinpoint')) bgColor = '#2e5cb8'

          return (
            <Typography
              sx={{
                backgroundColor: bgColor,
                color: 'white',
                borderRadius: '12px',
                padding: '4px',
                display: 'inline-block',
                width: '120px',
                textAlign: 'center',
              }}
            >
              {value}
            </Typography>
          )
        },
        AggregatedCell: ({ cell }) => {
          if (
            table.getColumn(cell.row.groupingColumnId ?? '').id ===
            'billingCountry'
          ) {
            return null
          }
          return (
            <IconButton
              onClick={() => {
                setSelectedOrganization({
                  value: cell.row.original.organizationId,
                  label: cell.row.original.organizationName,
                })
                setAddSubscriptionOpen(true)
              }}
            >
              <Add />
            </IconButton>
          )
        },
      },
      {
        header: 'Activation Date',
        accessorKey: 'effectiveAt',
        enableGrouping: false,
        filterVariant: 'date-range',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
        accessorFn: (row) => i18n.toDateShort(row.effectiveAt),
        size: 50,
      },
      {
        header: 'Expiry Date',
        accessorKey: 'expiresAt',
        enableGrouping: false,
        filterVariant: 'date-range',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
        accessorFn: (row) =>
          !!row.expiresAt ? i18n.toDateShort(row.expiresAt) : 'Never',
        size: 50,
      },
      {
        header: 'Status',
        accessorKey: 'status',
        enableGrouping: false,
        size: 50,
        enableColumnFilters: false,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        muiTableFooterCellProps: {
          align: 'center',
        },
        Cell: ({ cell }) => {
          const value = cell.getValue<string>()
          let textColor = '#262626'
          if (value === ACTIVE_STATUS) textColor = 'green'
          if (value === EXPIRED_STATUS) textColor = 'red'
          if (value === PENDING_STATUS) textColor = 'blue'

          return (
            <Typography
              sx={{
                color: textColor,
                borderRadius: '12px',
                padding: '4px',
                display: 'inline-block',
                width: '120px',
                textAlign: 'center',
              }}
              fontWeight="bold"
            >
              {value}
            </Typography>
          )
        },
      },
    ],
    [subscriptionTypesFilterOptions]
  )

  const emptySubscriptionView = () => {
    return (
      <Grid container justifyContent="center" alignItems="center" padding={4}>
        <Grid item xs={12} sm={6} md={4} sx={{ paddingLeft: '0 !important' }}>
          <Typography align="center">{'No Subscriptions Available'}</Typography>
        </Grid>
      </Grid>
    )
  }

  const table = useDefaultTableConfig({
    status: subscriptionRequest.status,
    columns,
    data: subscriptionRows,
    requiredTableConfig: {
      renderEmptyRowsFallback: emptySubscriptionView,
      rowCount: rowCount,
      state: {
        columnFilters,
        globalFilter,
        pagination: pagination,
        sorting,
      },
      onColumnFiltersChange: setColumnFilters,
      onGlobalFilterChange: setGlobalFilter,
      onPaginationChange: setPagination,
      onSortingChange: setSorting,
    },
    extendedTableConfig: {
      initialState: {
        density: 'compact',
        grouping: ['billingCountry', 'organizationName'], //an array of columns to group by by default (can be multiple)
        expanded: true,
      },
      enableGrouping: true,
      enableExpanding: true,
      enableExpandAll: true,
      enableColumnDragging: false,
      groupedColumnMode: 'remove',
      positionToolbarAlertBanner: 'none',
      enableRowActions: true,
      positionActionsColumn: 'last',
      rowCount: subscriptionRowCount,
      renderRowActions: ({ row }) => (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Tooltip title="Delete">
            <IconButton
              color="error"
              onClick={() => setDeleteSubscriptionRow(row.original)}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
      ),
      renderTopToolbarCustomActions: () => (
        <Stack spacing={2} padding={1} direction="row">
          <Button
            variant="outlined"
            onClick={() => {
              setSelectedOrganization(undefined)
              setAddSubscriptionOpen(true)
            }}
          >
            Add Subscription
          </Button>
          <FormControlLabel
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setShowFullHistory(e.target.checked)
            }
            control={<Checkbox value={showFullHistory} />}
            label="Show Full Subscription History"
          />
        </Stack>
      ),
    },
  })

  return (
    <AdminPage title="Admins">
      <Paper>
        <TableContainer>
          <MaterialReactTable table={table} />
        </TableContainer>
      </Paper>
      <AddSubscriptionModal
        open={addSubscriptionOpen}
        onConfirm={onAddSubscription}
        onCancel={onCancelAddSubscription}
        defaultSelectedOrganization={selectedOrganization}
        subscriptionTypes={subscriptionTypesFilterOptions}
      />
      <DeleteSubscriptionModal
        open={!!deleteSubscriptionRow}
        onConfirm={onDeleteSubscription}
        onCancel={onCancelDeleteSubscription}
        subscriptionType={deleteSubscriptionRow?.subscriptionTypeName ?? ''}
        organizationName={deleteSubscriptionRow?.organizationName ?? ''}
      />
    </AdminPage>
  )
}
