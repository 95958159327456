import { createSelector } from 'reselect'

import { createAsyncSelector } from '../AsyncSelector/createAsyncSelector'
import { gql, query } from '../graphql/client'
import { DeliveryParcel } from '../graphql/types'
import { RootStore } from '../redux/types'
import compareNumeric, { compareDates } from '../util/compareNumeric'
import { indexArray, indexMultiArrayMappedKey } from '../util/indexArray'
import { parseMetaArray } from '../util/parseMetaArray'
import { selectOrganizationId } from './selectOrganizationId'
import { getSelectedFlightDate } from './userSelectionRedux'

const first = <T>(arr: T[]): T | undefined => arr[0]
const last = <T>(arr: T[]): T | undefined => arr[arr.length - 1]

export interface ParcelData {
  id: string
  groupId: string
  name: string
  meta: any
  geometry: DeliveryParcel['geometry']
  deletedAt?: string
  retiredAt?: string
  group: GroupData
  parcelFlightDates: {
    flightDates: string[]
  } | null
  flightDates: string[]
}

export interface GroupData {
  id: string
  name: string
  parcels: ParcelData[]

  flightDates: string[]
}

export interface DeliveryGroupDate {
  deliveryId: string
  groupId: string
  flightDate: string
  mapLayerDefIds: string[]
  mapSourceIds: string[]
  filenames: string[]
  enabledFilenames: string[]
  enabledMapLayerDefIds: string[]
}

interface ParcelFilters {
  deliveryGroupDates: DeliveryGroupDate[]
  deliveryFlightDates: {
    deliveryId: string
    flightDates: string[]
  }[]
  groups: GroupData[]

  parcels: ParcelData[]

  parcelFilterStats: {
    mapLayerDefIds: string[]
    varietals: string[]
    rootstock: string[]
    trellis: string[]
  }[]

  parcelFlightDates: {
    parcelId: string
    flightDates: string[]
  }[]
}

interface OrganizationGroup {
  name: string
  id: number
}

export interface OrgMapDataData {
  parcelFilters?: ParcelFilters
  groups: OrganizationGroup[]
}

const parseMetaProperty = (arr: (string | string[])[]) => {
  const set = new Set<string>()

  for (const value of arr) {
    if (!value) {
      continue
    }

    let valueArr: string[] = []

    if (typeof value === 'string') {
      valueArr = parseMetaArray(JSON.parse(value))
    } else {
      valueArr = value
    }

    for (const val of valueArr) {
      set.add(val)
    }
  }

  return Array.from(set).sort((a, b) => a.localeCompare(b))
}

const { selector: selectOrgMapData, refresh: refreshOrgMapData } =
  createAsyncSelector({
    resource: 'me.organization',
    inputs: {
      organizationId: selectOrganizationId,
      isLoggedIn: (state: RootStore) => state.login.isLoggedIn,
      isLoginInProgress: (state: RootStore) => state.login.isInProgress,
      isPostLoginInProgress: (state: RootStore) =>
        state.login.isPostLoginInProgress,
    },
    fetcher: async (
      {
        organizationId,
        isLoggedIn,
        isLoginInProgress,
        isPostLoginInProgress,
      }: {
        organizationId?: string
        isLoggedIn: boolean
        isLoginInProgress: boolean
        isPostLoginInProgress: boolean
      },
      skip
    ) => {
      if (
        !organizationId ||
        !isLoggedIn ||
        isLoginInProgress ||
        isPostLoginInProgress
      ) {
        return skip()
      }

      const { data } = await query<{
        data: OrgMapDataData
      }>({
        query: gql`
          query ORG_MAP_DATA($organizationId: Int!) {
            data: Organization_by_pk(id: $organizationId) {
              groups: OrganizationGroups {
                name
                id
              }
              parcelFilters: ParcelFilters {
                deliveryGroupDates: DeliveryGroupDates(
                  order_by: { flightDate: desc }
                ) {
                  deliveryId
                  groupId
                  flightDate
                  mapLayerDefIds
                  mapSourceIds
                  filenames
                  enabledFilenames
                  enabledMapLayerDefIds
                }
                parcelFilterStats: ParcelFilterStats_PerGroup(
                  where: { OrganizationGroup: {} }
                ) {
                  mapLayerDefIds
                  varietals
                  rootstock
                  trellis
                }
                deliveryFlightDates: DeliveryFlightDates {
                  deliveryId
                  flightDates
                }
                parcelFlightDates: ParcelFlightDates {
                  parcelId
                  flightDates
                }
                groups: OrganizationGroups(
                  where: { deletedAt: { _is_null: true } }
                ) {
                  id
                  name
                  parcels: Parcels(where: { deletedAt: { _is_null: true } }) {
                    id
                    groupId
                    name
                    meta
                    geometry
                    deletedAt
                    retiredAt
                  }
                }
              }
            }
          }
        `,
        variables: {
          organizationId,
        },
      })

      if (!data?.parcelFilters) {
        return data
      }

      data.parcelFilters?.deliveryFlightDates.sort((a, b) => {
        const startA = Date.parse(first(a.flightDates)!)
        const startB = Date.parse(first(b.flightDates)!)
        const endA = Date.parse(last(a.flightDates)!)
        const endB = Date.parse(last(b.flightDates)!)

        const start = startB - startA
        const end = endB - endA

        if (start !== 0) {
          return start
        }

        return end
      })

      data.parcelFilters.parcels = []

      data.parcelFilters.groups = data.parcelFilters.groups.sort((a, b) =>
        compareNumeric(a.name, b.name)
      )
      data.parcelFilters?.groups.forEach((group) => {
        group.parcels = group.parcels.sort((a, b) =>
          compareNumeric(a.name, b.name)
        )

        data!.parcelFilters!.parcels =
          data.parcelFilters?.parcels.concat(group.parcels) || []

        const groupDates = new Set<string>()

        group.parcels.forEach((parcel) => {
          parcel.group = group
          parcel.flightDates = []

          const parcelFlightDate = data.parcelFilters?.parcelFlightDates.find(
            ({ parcelId }) => parcel.id === parcelId
          )

          if (parcelFlightDate) {
            parcel.flightDates = parcelFlightDate.flightDates
            parcel.parcelFlightDates = {
              flightDates: parcelFlightDate.flightDates ?? [],
            }
            groupDates.add(last(parcel.flightDates)!)
          }
        })

        group.flightDates = Array.from(groupDates).sort(compareDates('asc'))
      })

      const varietals = new Set<string>()
      const rootstock = new Set<string>()
      const trellis = new Set<string>()
      const mapLayerDefIds = new Set<string>()

      if (data.parcelFilters.parcelFilterStats) {
        for (const parcelFilterStats of data.parcelFilters.parcelFilterStats) {
          if (typeof parcelFilterStats.mapLayerDefIds === 'string') {
            ;(JSON.parse(parcelFilterStats.mapLayerDefIds) as string[]).forEach(
              (id) => mapLayerDefIds.add(id)
            )
          } else {
            ;(parcelFilterStats.mapLayerDefIds ?? []).forEach((id) =>
              mapLayerDefIds.add(id)
            )
          }
          if (typeof parcelFilterStats.varietals === 'string') {
            parseMetaProperty(JSON.parse(parcelFilterStats.varietals)).forEach(
              (v) => varietals.add(v)
            )
          } else {
            parseMetaProperty(parcelFilterStats.varietals ?? []).forEach((v) =>
              varietals.add(v)
            )
          }
          if (typeof parcelFilterStats.rootstock === 'string') {
            parseMetaProperty(JSON.parse(parcelFilterStats.rootstock)).forEach(
              (rs) => rootstock.add(rs)
            )
          } else {
            parseMetaProperty(parcelFilterStats.rootstock ?? []).forEach((rs) =>
              rootstock.add(rs)
            )
          }

          if (typeof parcelFilterStats.trellis === 'string') {
            parseMetaProperty(JSON.parse(parcelFilterStats.trellis)).forEach(
              (t) => trellis.add(t)
            )
          } else {
            parseMetaProperty(parcelFilterStats.trellis ?? []).forEach((t) =>
              trellis.add(t)
            )
          }
        }
      }

      data.parcelFilters.parcelFilterStats = [
        {
          varietals: Array.from(varietals),
          rootstock: Array.from(rootstock),
          trellis: Array.from(rootstock),
          mapLayerDefIds: Array.from(mapLayerDefIds),
        },
      ]

      return data
    },
  })

export { selectOrgMapData, refreshOrgMapData }

const PARCEL_FILTER_STATS = [
  {
    mapLayerDefIds: [],
    varietals: [],
    rootstock: [],
    trellis: [],
  },
]

export const selectParcelFilterStats = createSelector(
  [selectOrgMapData],
  (orgMapData) =>
    orgMapData.data?.parcelFilters?.parcelFilterStats
      ? orgMapData.data.parcelFilters.parcelFilterStats
      : PARCEL_FILTER_STATS
)

export const parcelFilterStatsProducts = createSelector(
  [selectParcelFilterStats],
  ([parcelFilterStats]) => parcelFilterStats.mapLayerDefIds
)

export const parcelFilterStatsVarietals = createSelector(
  [selectParcelFilterStats],
  ([parcelFilterStats]) => parcelFilterStats.varietals
)
export const parcelFilterStatsRootstock = createSelector(
  [selectParcelFilterStats],
  ([parcelFilterStats]) => parcelFilterStats.rootstock
)
export const parcelFilterStatsTrellis = createSelector(
  [selectParcelFilterStats],
  ([parcelFilterStats]) => parcelFilterStats.trellis
)

const DELIVERY_GROUP_DATES: ParcelFilters['deliveryGroupDates'] = []

export const selectGroupDates = createSelector(
  [selectOrgMapData],
  (orgMapData) =>
    orgMapData.data?.parcelFilters?.deliveryGroupDates
      ? orgMapData.data.parcelFilters.deliveryGroupDates
      : DELIVERY_GROUP_DATES
)

export const selectGroupDatesByFlightDate = createSelector(
  [selectGroupDates],
  (groupDates) =>
    indexMultiArrayMappedKey(groupDates, ({ flightDate }) => flightDate)
)

export const selectGroupDatesByDeliveryGroupId = createSelector(
  [selectGroupDates],
  (groupDates) =>
    indexMultiArrayMappedKey(
      groupDates,
      ({ deliveryId, groupId }) => `${deliveryId}_${groupId}`
    )
)

const GROUP_IDS: string[] = []
export const selectAvailableGroupIdsForFlightDate = createSelector(
  [
    selectGroupDatesByFlightDate,
    (state: RootStore) => state.userSelection.selectedFlightDate,
  ],
  (groupDatesByFlightDate, flightDate) =>
    flightDate
      ? (groupDatesByFlightDate[flightDate] ?? []).map(({ groupId }) => groupId)
      : GROUP_IDS
)

export const selectAvailableGroupIdsFromFlightDateParameter = createSelector(
  [
    selectGroupDatesByFlightDate,
    (_rootStore: RootStore, flightDate: string) => flightDate,
  ],
  (groupDatesByFlightDate, flightDate) =>
    flightDate
      ? (groupDatesByFlightDate[flightDate] ?? []).map(({ groupId }) => groupId)
      : GROUP_IDS
)

export const selectAvailableFilenames = createSelector(
  selectGroupDates,
  (groupDates) => {
    return Array.from(
      groupDates.reduce((filenames, groupDate) => {
        for (const filename of groupDate.filenames) {
          filenames.add(filename)
        }

        return filenames
      }, new Set<string>())
    )
  }
)

export const selectGroupDatesByGroupId = createSelector(
  [selectGroupDates],
  (groupDates) => indexMultiArrayMappedKey(groupDates, ({ groupId }) => groupId)
)

export const selectGroupDatesByGroupIdAndDeliveryId = createSelector(
  [selectGroupDates],
  (groupDates) =>
    indexMultiArrayMappedKey(
      groupDates,
      ({ groupId, deliveryId }) => `${groupId}/${deliveryId}`
    )
)
export const selectGroupDatesByGroupIdAndFlightDate = createSelector(
  [selectGroupDates],
  (groupDates) =>
    indexMultiArrayMappedKey(
      groupDates,
      ({ groupId, flightDate }) => `${groupId}/${flightDate}`
    )
)

export const selectDeliveryIdsByGroupIdAndFlightDate = createSelector(
  [selectGroupDates],
  (groupDates) =>
    indexMultiArrayMappedKey(
      groupDates,
      ({ groupId, flightDate }) => `${groupId}/${flightDate}`,
      ({ deliveryId }) => deliveryId
    )
)
const DELIVERY_IDS: string[] = []
export const selectDeliveryIds = createSelector(
  [
    selectDeliveryIdsByGroupIdAndFlightDate,
    (state: RootStore) => state.userSelection.selectedGroupId,
    (state: RootStore) => state.userSelection.selectedFlightDate,
  ],
  (deliveryIdsByGroupIdAndFlightDate, groupId, flightDate) => {
    return (
      deliveryIdsByGroupIdAndFlightDate[`${groupId}/${flightDate}`] ||
      DELIVERY_IDS
    )
  }
)

export const selectGroupDatesBySelectedGroupId = createSelector(
  [
    selectGroupDatesByGroupId,
    (state: RootStore) => state.userSelection.selectedGroupId,
  ],
  (groupDates, groupId) => groupDates[groupId!] || DELIVERY_GROUP_DATES
)

export const selectGroupDatesBySelectedFlightDate = createSelector(
  [
    selectGroupDatesByFlightDate,
    (state: RootStore) => state.userSelection.selectedFlightDate,
  ],
  (groupDates, flightDate) => groupDates[flightDate!] || DELIVERY_GROUP_DATES
)

export const selectDateMenuData = createSelector(
  [selectGroupDates, selectGroupDatesBySelectedGroupId],
  (groupDates, selectedGroupDates) => {
    const selectedDates = Array.from(
      new Set(selectedGroupDates.map(({ flightDate }) => flightDate))
    )

    const otherDates = Array.from(
      new Set(groupDates.map(({ flightDate }) => flightDate))
    ).filter((flightDate) => !selectedDates.includes(flightDate))

    return { selectedDates, otherDates }
  }
)

export const selectDateMenuDataWithFilter = createSelector(
  [
    selectGroupDates,
    selectGroupDatesBySelectedGroupId,
    (_: RootStore, filter: (deliveryGroupDate: DeliveryGroupDate) => boolean) =>
      filter,
  ],
  (groupDates, selectedGroupDates, filter) => {
    const selectedDates = Array.from(
      new Set(selectedGroupDates.map(({ flightDate }) => flightDate))
    )

    const filteredDates = Array.from(
      new Set(
        groupDates
          .filter((gd) => {
            const isFiltered = filter(gd)
            return isFiltered
          })
          .map(({ flightDate }) => flightDate)
      )
    ).filter((flightDate) => !selectedDates.includes(flightDate))

    const otherDates = Array.from(
      new Set(groupDates.map(({ flightDate }) => flightDate))
    ).filter(
      (flightDate) =>
        !selectedDates.includes(flightDate) &&
        !filteredDates.includes(flightDate)
    )

    return { selectedDates, otherDates, filteredDates }
  }
)

const DELIVERY_DATES: ParcelFilters['deliveryFlightDates'] = []

export const selectDeliveryDates = createSelector(
  [selectOrgMapData],
  (orgMapData) =>
    orgMapData.data?.parcelFilters?.deliveryFlightDates
      ? orgMapData.data.parcelFilters.deliveryFlightDates
      : DELIVERY_DATES
)

const GROUPS: GroupData[] = []

export const selectGroups = createSelector([selectOrgMapData], (orgMapData) => {
  return orgMapData.data?.parcelFilters?.groups
    ? orgMapData.data.parcelFilters.groups
    : GROUPS
})

export const selectGroupsById = createSelector([selectGroups], (groups) => {
  return indexArray(groups, 'id')
})

const EMPTY_GROUPS: OrganizationGroup[] = []

export const selectOrganizationGroups = createSelector(
  selectOrgMapData,
  (orgMapData) => orgMapData.data?.groups ?? EMPTY_GROUPS
)

export const selectOrganizationGroupsById = createSelector(
  selectOrganizationGroups,
  (organizationGroups) => indexArray(organizationGroups, 'id')
)

const PARCELS: ParcelData[] = []

const getParcelsForFlightDate = (
  orgMapData: ReturnType<typeof selectOrgMapData>,
  flightDate: ReturnType<typeof getSelectedFlightDate>
) => {
  if (flightDate === undefined) {
    return orgMapData.data?.parcelFilters?.parcels ?? PARCELS
  }

  const parsedFlightDate = Date.parse(flightDate)
  const eligibleParcels =
    orgMapData?.data?.parcelFilters?.parcels?.filter(
      ({ deletedAt, retiredAt }) => {
        let isEligible = true

        isEligible =
          isEligible &&
          !(!!deletedAt && Date.parse(deletedAt) <= parsedFlightDate)
        isEligible =
          isEligible &&
          !(!!retiredAt && Date.parse(retiredAt) <= parsedFlightDate)

        return isEligible
      }
    ) ?? PARCELS

  return eligibleParcels
}

export const selectParcels = createSelector(
  [selectOrgMapData, getSelectedFlightDate],
  getParcelsForFlightDate
)

const getFlightDateParameter = (_: RootStore, flightDate: string) => flightDate

export const selectParcelsWithFlightDate = createSelector(
  [selectOrgMapData, getFlightDateParameter],
  getParcelsForFlightDate
)

export const selectParcelsById = createSelector([selectParcels], (parcels) =>
  indexArray(parcels, 'id')
)

export const selectParcelByIdWithFlightDate = createSelector(
  [selectParcelsWithFlightDate],
  (parcels) => indexArray(parcels, 'id')
)

const getAllParcels = (orgMapData: ReturnType<typeof selectOrgMapData>) => {
  const eligibleParcels =
    orgMapData?.data?.parcelFilters?.parcels?.filter(
      (parcel) => !parcel.deletedAt
    ) ?? PARCELS

  return eligibleParcels
}

export const selectAllParcels = createSelector(
  [selectOrgMapData],
  getAllParcels
)

export const selectAllParcelsById = createSelector(
  [selectAllParcels],
  (parcels) => indexArray(parcels, 'id')
)
