import * as React from 'react'
import { ConfirmationModal } from '../../app/ConfirmationModal/ConfirmationModal'
import { ProjectType } from '../../graphql/types'
import { useRedux } from '../../hooks/useRedux'
import i18n, { keys } from '../../i18n'
import * as notes from '../../notes/redux'
import { refreshListProjects } from '../../notes/selectors'
import { createProject } from '../queries'
import { CreateProjectForm } from './CreateProjectForm'

interface Props {
  open: boolean
  onCancel: () => void
  onProjectCreate: (id: string) => void
  type: ProjectType
  organizationId: string | undefined
}

export const CreateProjectModal = ({
  open,
  onCancel,
  onProjectCreate,
  type,
  organizationId,
}: Props) => {
  const [state, dispatch] = useRedux()
  const [name, setName] = React.useState('')
  const [instructions, setInstructions] = React.useState('')

  React.useEffect(() => {
    if (!open) {
      setName('')
      setInstructions('')
    }
  }, [open])

  const createNewProject = async () => {
    const result = await createProject({
      name: name,
      organizationId,
      description: instructions,
      type: type,
    })
    if (result.data) {
      refreshListProjects()
      onProjectCreate(result.data.id)
      dispatch(
        notes.actions.setSelectedProjectIds([
          ...state.notes.selectedProjectIds,
          result.data.id,
        ])
      )
    }
  }

  return (
    <ConfirmationModal
      open={open}
      title={i18n.t(keys.createProjectLabel)}
      content={
        <CreateProjectForm
          name={name}
          instructions={instructions}
          onNameChange={(e) => setName(e.target.value)}
          onInstructionsChange={(e) => setInstructions(e.target.value)}
        />
      }
      onConfirm={createNewProject}
      onCancel={onCancel}
    />
  )
}
