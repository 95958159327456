import { Autocomplete, FormControl, TextField, Typography } from '@mui/material'
import React from 'react'
import { indexArray } from '../../../util/indexArray'

interface AddSubscriptionFormProps {
  subscriptionTypes: {
    value: string
    label: string
  }[]
  selectedSubscriptionType: string
  setSelectedSubscriptionType: (value: string) => void
}

export const SubscriptionSelect = ({
  subscriptionTypes,
  selectedSubscriptionType,
  setSelectedSubscriptionType,
}: AddSubscriptionFormProps) => {
  const subscriptionTypesById = React.useMemo(
    () => indexArray(subscriptionTypes, 'value'),
    [subscriptionTypes]
  )
  return (
    <FormControl>
      <Typography>Subscription Type</Typography>
      <Autocomplete
        value={subscriptionTypesById[selectedSubscriptionType]}
        options={subscriptionTypes}
        getOptionLabel={(option) => option.label}
        onChange={(_, v) => setSelectedSubscriptionType(v?.value || '')}
        renderInput={(params) => (
          <TextField {...params} label="Select Subscription Type..." />
        )}
      />
    </FormControl>
  )
}
