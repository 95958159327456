import { Button, alpha, styled, Tooltip, darken } from '@mui/material'
import React from 'react'
import i18n, { keys } from '../i18n'
import { Lock } from '@mui/icons-material'
import { useRedux } from '../hooks/useRedux'
import { toggleSubscriptionDialog } from './actions'

interface Props {
  locked?: boolean
  onClick?: () => void
}

const LockButton = styled(Button)(({ theme }) => ({
  backgroundColor: alpha(darken(theme.palette.background.paper, 0.2), 0.5),
  minWidth: '32px',
  maxWidth: '32px',
  borderRadius: '4px',
}))

const LockIcon = styled(Lock)(({ theme }) => ({
  color: theme.palette.text.primary,
}))

export const SubscriptionLock = ({ locked, onClick }: Props) => {
  const [_, dispatch] = useRedux()
  const onClickLock = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault()
    event.stopPropagation()

    onClick && onClick()
    dispatch(toggleSubscriptionDialog(true))
  }
  if (!locked) {
    return null
  }

  return (
    <Tooltip
      placement="bottom-start"
      title={i18n.t(keys.dateOlderThanLimit)}
      onClick={onClickLock}
    >
      <LockButton disabled={false}>
        <LockIcon />
      </LockButton>
    </Tooltip>
  )
}
