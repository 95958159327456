import { List, ListItem, Typography } from '@mui/material'
import * as React from 'react'
import AsyncSelectorStatusOverlay from '../../AsyncSelector/AsyncSelectorStatusOverlay'
import { selectFilteredGroups } from '../../data/selectFilteredGroups'
import {
  selectAvailableGroupIdsForFlightDate,
  selectOrgMapData,
} from '../../data/selectOrgMapData'
import { selectParcelFilter } from '../../data/selectParcelFilter'
import { getSelectedFlightDate } from '../../data/userSelectionRedux'
import { useRedux } from '../../hooks/useRedux'
import i18n, { keys } from '../../i18n'
import useSubscriptionFeature from '../../subscriptions/hooks/useSubscriptionFeature'
import { splitFilter } from '../../util/splitFilter'
import AdvancedFilters from './AdvancedFilters/AdvancedFilters'
import GroupListItem from './GroupListItem'
import ParcelFilter from './ParcelFilter'

const ParcelDrawer: React.FC = () => {
  const [state] = useRedux()
  const groups = selectFilteredGroups(state)
  const OrgMapDataRequest = selectOrgMapData(state)
  const parcelFilterRequest = selectParcelFilter(state)
  const availableGroupIds = selectAvailableGroupIdsForFlightDate(state)
  const selectedFlightDate = getSelectedFlightDate(state)
  const { limit: flightDateLimitInYears } = useSubscriptionFeature({
    featureType: 'historical-data',
  })
  const [availableGroupData, unavailableGroupData] = React.useMemo(
    () =>
      splitFilter(groups, (group) => {
        return availableGroupIds.includes(group.id)
      }),
    [availableGroupIds, groups]
  )

  const availableGroups = availableGroupData.map((group) => (
    <GroupListItem
      key={group.id}
      group={group}
      flightDateLimitInYears={flightDateLimitInYears}
    />
  ))

  const unavailableGroups = unavailableGroupData.map((group) => (
    <GroupListItem
      key={group.id}
      group={group}
      flightDateLimitInYears={flightDateLimitInYears}
    />
  ))

  return (
    <AsyncSelectorStatusOverlay
      requests={OrgMapDataRequest}
      hideChildrenWhenLoading
    >
      <List dense>
        <ParcelFilter />
        <AdvancedFilters />
        <ListItem disabled>
          <Typography variant="subtitle1">
            {i18n.t(keys.parcelDrawer.selectParcels)}
          </Typography>
        </ListItem>
        <AsyncSelectorStatusOverlay
          hideChildrenWhenLoading
          requests={[OrgMapDataRequest, parcelFilterRequest]}
        >
          {availableGroups}
          {unavailableGroups.length > 0 && selectedFlightDate && (
            <ListItem disabled>
              <Typography variant="subtitle1">
                {i18n.t(keys.parcelDrawer.unavailableForSelectedDate)}
              </Typography>
            </ListItem>
          )}
          {unavailableGroups}
          {groups.length === 0 && (
            <ListItem disabled>
              <Typography variant="caption">
                {i18n.t(keys.parcelDrawer.noResults)}
              </Typography>
            </ListItem>
          )}
        </AsyncSelectorStatusOverlay>
      </List>
    </AsyncSelectorStatusOverlay>
  )
}

export default ParcelDrawer
