import * as React from 'react'
import { PureComponent } from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import MenuList from '@mui/material/MenuList'
import TextField from '@mui/material/TextField'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import * as actions from '../app/actions'
import { isChildUrl, RouteParams, url, urls } from '../appNavigation/urls'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material'
import { selectOrganizationId } from '../data/selectOrganizationId'
import { selectOrganizations } from '../data/selectOrganizations'
import * as userSelection from '../data/userSelectionRedux'
import i18n, { keys } from '../i18n'
import { connect } from '../redux/connect'
import { AppDispatchProps, RootStore } from '../redux/types'
import fuzzySearch from '../util/fuzzySearch'
import { isNumericIdEqual } from '../util/isNumericIdEqual'
import { fetchSubscriptionFeatures } from '../subscriptions/actions'
import { selectMe } from '../data/selectMe'

interface State {
  filter: string
}

class OrgSwitchDialog extends PureComponent<
  ReduxProps & AppDispatchProps & RouteComponentProps<RouteParams>,
  State
> {
  state: State = {
    filter: '',
  }

  render() {
    const {
      organizationId,
      isOrgSwitchDialogOpen,
      organizations = [],
    } = this.props
    const { filter } = this.state
    const filterAdornmentIcon = filter ? 'cancel' : 'search'

    const filteredOrganizations = (
      filter
        ? organizations.filter(({ name }) => fuzzySearch(filter, name))
        : organizations
    ).sort((orgA, orgB) => orgA.name.localeCompare(orgB.name))

    return (
      <Dialog open={isOrgSwitchDialogOpen} onClose={this.handleClose}>
        <DialogTitle>{i18n.t(keys.user.switchOrganization)}</DialogTitle>
        <DialogContent>
          {(organizations.length > 5 || filter !== '') && (
            <TextField
              fullWidth
              placeholder="Filter!"
              value={filter}
              onChange={this.handleFilterChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon onClick={this.handleFilterClear}>
                      {filterAdornmentIcon}
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          )}
          <MenuList>
            {filteredOrganizations.map((org) => (
              <MenuItem
                selected={isNumericIdEqual(org.id, organizationId)}
                key={org.id}
                onClick={() => {
                  this.handleOrganizationSwitch(org.id)
                }}
              >
                <ListItemIcon>
                  <Icon>group</Icon>
                </ListItemIcon>
                <ListItemText primary={org.name} />
              </MenuItem>
            ))}
          </MenuList>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>
            {i18n.t(keys.generic.close)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  handleOrganizationSwitch = async (organizationId: string) => {
    await this.props.dispatch(
      userSelection.update({ selectedOrganizationId: organizationId })
    )
    const { pathname } = this.props.history.location
    const difference = isChildUrl(urls.organizationSettings, pathname)
    if (difference) {
      this.props.history.replace(
        url(
          { url: `${urls.organizationSettings.url}/${difference}` },
          { organizationId }
        )
      )
    }
    fetchSubscriptionFeatures(
      organizationId,
      !!this.props.isAdmin,
      this.props.dispatch
    )

    this.handleClose()
  }

  handleClose = () => {
    this.props.dispatch(actions.setOrgSwitchDialogOpen(false))
  }

  handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filter: e.target.value,
    })
  }

  handleFilterClear = () => {
    this.setState({
      filter: '',
    })
  }
}

const mapState = (state: RootStore) => ({
  organizations: selectOrganizations(state),
  organizationId: selectOrganizationId(state),
  isOrgSwitchDialogOpen: state.app.isOrgSwitchDialogOpen,
  isAdmin: selectMe(state)?.roles?.includes('admin'),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withRouter(OrgSwitchDialog)
)
